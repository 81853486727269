import React, { useState } from "react";
import { useFilter } from "../../Tools/APIs/useMyAPI";
import moment from "moment";

export default function FilterStatistics({ setFilterUrl }) {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const filter = () => {
    setFilterUrl(
      `admin/statistics?temp=0${startDate ? `&start_date=${startDate}` : ""}${
        endDate ? `&end_date${endDate}` : ""
      }`
    );
  };

  const handleSelect = (event) => {
    let start_date;
    const { value } = event;
    if (value === "last-month") {
      const lastMonth = moment().subtract(30, "days").format(`YYYY-MM-DD`);
      start_date = lastMonth;
    } else if (value === "last-week") {
      const lastWeek = moment().subtract(7, "days");
      start_date = lastWeek;
    } else if (value === "last-day") {
      const lastDay = moment().subtract(1, "days");
      start_date = lastDay;
    }
    setFilterUrl(
      `admin/statistics${start_date ? `?start_date=${start_date}` : ""}`
    );
  };

  return (
    <div className="space-y-4 my-4 sm:w-2/3 mx-auto">
      <h3 className="font-bold mb-4 text-xl font-gray-700">فتلرة الإحصائيات</h3>
      <div className="flex justify-center items-center gap-4">
        <label className="w-full">
          تاريخ البداية
          <input
            type="date"
            className="mt-2 py-2 px-3 rounded-sm border"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </label>
        <label className="w-full">
          تاريخ النهاية
          <input
            type="date"
            value={endDate}
            className="mt-2 py-2 px-3 rounded-sm border"
            onChange={(e) => setEndDate(e.target.value)}
          />
        </label>
        <label className="w-full">
          فلتر حسب
          <select defaultValue="all" onChange={handleSelect}>
            <option value="all">الكل</option>
            <option value="last-month">اخر شهر</option>
            <option value="last-week">اخر اسبوع</option>
            <option value="last-day">اخر يوم</option>
          </select>
        </label>
      </div>
      <div className="flex justify-center gap-4 items-center">
        {(startDate || endDate) && (
          <>
            <button
              className="px-2 py-3 bg-Main text-white rounded-md shadow-sm border"
              onClick={filter}
            >
              فلترة
            </button>
            <button
              className="px-2 py-3 rounded-md shadow-sm border"
              onClick={() => {
                setStartDate("");
                setEndDate("");
                setFilterUrl();
              }}
            >
              إلغاء الفلترة
            </button>
          </>
        )}
      </div>
    </div>
  );
}
