import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import App from "./App";
import ContextProvider from "./Context/ContextOPen";
import "./index.css";
import ContextTranslate from "./Translate/ContextTranslate";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  // {/* <BrowserRouter> */}
  <ContextTranslate>
    <ContextProvider>
      <HashRouter>
        <App />
      </HashRouter>
    </ContextProvider>
  </ContextTranslate>
  // {/* </BrowserRouter> */}
  // </React.StrictMode>
);
