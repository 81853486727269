import React, { createContext, useContext, useState } from "react";

const ContextOpen = createContext({});

const ContextProvider = ({ children }) => {
  const [openMenu, setOpenMenu] = useState(true);
  const [message, setMessage] = useState([]);
  const [successMessage, setSuccessMessage] = useState([]);
  const [showUserImages, setShowUserImages] = useState(["", false]);

  return (
    <ContextOpen.Provider
      value={{
        setOpenMenu,
        openMenu,
        message,
        setMessage,
        successMessage,
        setSuccessMessage,
        showUserImages,
        setShowUserImages,
      }}>
      {children}
    </ContextOpen.Provider>
  );
};

export default ContextProvider;

export const useContextHook = () => {
  return useContext(ContextOpen);
};
