import { useContextTranslate } from "../../Translate/ContextTranslate";

function TitleEdit({ title }) {
  const { content } = useContextTranslate();
  return (
    <div>
      <p className="font-bold text-xl mx-3 mb-5">
        {title || content.BasicInformation}
      </p>
    </div>
  );
}
export default TitleEdit;
