import { useLocation, useNavigate } from "react-router-dom";
import img1 from "../../Svg/Frame 20.svg";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Add, MainTitle, Statistics, Table } from "../../components";
import UsernameSearch from "../../components/Popup/UsernameSearch";
import PopUpAdvancedSearch from "./PopUpAdvancedSearch";
import { useEffect } from "react";

function Users() {
  const { data: user } = useFETCH("admin/profile");
  const isAdmin = user?.data?.data?.role?.name === "super_admin";
  const { content } = useContextTranslate();
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/users${search}`,
    `admin/users`
  );

  useEffect(() => {
    navigate(pathname + search);
  }, [search]);

  const dataAll = data?.data.data.data?.map((e) => {
    return {
      id: e?.login_details?.id,
      images:
        e?.images.length > 0
          ? fileUrl + e?.images[e?.images?.length - 1].image
          : "http",
      username: e.login_details?.username,
      email: e.login_details?.email,
      phone_number: e.secret_data?.phone_number,
      gender: e.gender === "FEMALE" ? "أنثى" : "ذكر",
      country: e.nationality_residence?.country?.name,
      counter_reports_count: e.counter_reports_count
        ? e.counter_reports_count
        : "0",
    };
  });

  return (
    <>
      <MainTitle title={content.Users} />
      <Container>
        <Row justify="between" className="items-center">
          <Col lg={3} md={4} sm={6}>
            <Statistics
              img={img1}
              name={content.UsersNumber}
              number={data?.data.data.total}
            />
          </Col>
          <Col className="flex gap-4 items-center justify-between flex-wrap">
            <Col col={10} md={8}>
              <UsernameSearch />
            </Col>
            <Col col={10} md={3}>
              <PopUpAdvancedSearch
                total={data?.data.data.total}
                user={user?.data?.data}
              />
            </Col>
          </Col>
        </Row>
        <Row justify="between" className="bg-white shadow-sm rounded-2xl p-5">
          <Add
            hidBtn={!isAdmin}
            titles={content.Users}
            title={content.User}
            link="/users/add"
          />
          <Table
            isAdmin={isAdmin}
            thead={[
              content.Images,
              content.username,
              content.Email,
              content.Phone,
              content.Gender,
              content.Country,
              content.counterReports,
            ]}
            pageCount={Math.ceil(
              data?.data.data?.total / data?.data.data?.per_page
            )}
            tData={dataAll}
            tbody={[
              "images",
              "username",
              "email",
              "phone_number",
              "gender",
              "country",
              "counter_reports_count",
            ]}
            show="users/details"
            showContent="عرض معلومات المستخدم"
            edit="users"
            funDelete={deleteItem}
            userId="true"
            report="true"
            packages
            isLoading={isLoading}
          />
        </Row>
      </Container>
    </>
  );
}
export default Users;
