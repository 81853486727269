import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import {
  Back,
  Input,
  MultipleSelect,
  PasswordInput,
  TitleEdit,
} from "../../components";
function AddNationalities({ rout }) {
  const navigate = useNavigate();
  const { data: user } = useFETCH("admin/profile");
  const role = user?.data?.data?.role?.name;
  useEffect(() => {
    console.log(role)
    // if (role == "super_admin") navigate("/admin");
  }, [role]);

  const { content } = useContextTranslate();
  const { id } = useParams();
  const {
    handleChangeSelect,
    handleChangeInput,
    handleSubmit,
    setFormData,
    formData,
    loading,
  } = usePOST({});
  const { data, isLoading } = useFETCH(
    id === "add" ? "" : `admin/admins/${id}`
  );
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id === "add" ? `admin/admins` : `admin/admins/${id}`);
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        ...formData,
        _method: "PUT",
        name: dataOld?.name,
        email: dataOld?.email,
        gender: dataOld?.gender,
        role_id: 1,
        password: dataOld?.password,
        username: dataOld?.username,
      });
  }, [dataOld]);
  return (
    <div>
      <Container>
        <Col lg={8} md={10} className="mx-auto">
          <Row justify="center" className="bg-white shadow-sm rounded-2xl p-5">
            <TitleEdit />
            <Col>
              <MultipleSelect
                icon={true}
                name="gender"
                text={content.Gender}
                value={formData?.gender}
                options={[
                  { name: "ذكر", id: "MALE" },
                  { name: "أنثى", id: "FEMALE" },
                ]}
                onChange={handleChangeSelect}
              />
            </Col>
            <Col>
              <MultipleSelect
                name="role_id"
                text={content.Role}
                value={formData?.role_id}
                options={[
                  { name: "مشرف", id: "1" },
                  { name: "مسؤول", id: "2" },
                ]}
                onChange={handleChangeSelect}
              />
            </Col>
            <Col>
              <Input
                name="name"
                onChange={handleChangeInput}
                placeholder={content?.Name}
                value={formData?.name}
              />
            </Col>
            <Col>
              <Input
                name="username"
                onChange={handleChangeInput}
                placeholder={content?.Username}
                value={formData?.username}
              />
            </Col>
            <Col>
              <Input
                type="email"
                name="email"
                onChange={handleChangeInput}
                placeholder={content?.Email}
                value={formData?.email}
              />
            </Col>
            <Col>
              <PasswordInput
                name="password"
                value={formData?.password}
                onChange={handleChangeInput}
              />
            </Col>
            {loading || (id !== "add" && isLoading) ? <c /> : ""}
            <Back name="حفظ" onClick={handleSubmitMain} />
          </Row>
        </Col>
      </Container>
    </div>
  );
}
export default AddNationalities;
