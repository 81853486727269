import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Back, Input, MultipleSelect, TitleEdit } from "../../components";
function AddNationalities({ rout }) {
  const { content } = useContextTranslate();
  const { id } = useParams();
  const {
    handleChangeInput,
    handleChangeSelect,
    handleSubmit,
    setFormData,
    formData,
    loading,
  } = usePOST({
    type:
      rout === "beard-statuses"
        ? "MALE"
        : rout === "hijab-statuses"
        ? "FEMALE"
        : "*",
  });
  const { data, isLoading } = useFETCH(
    id === "add" ? "" : `admin/lists/${rout}/items/${id}`
  );
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      id === "add" ? `admin/lists/${rout}` : `admin/lists/${rout}/items/${id}`
    );
  };
  let dataOld = data?.data.data;
  const { data: country } = useFETCH(`admin/lists/countries?type=*`);
  const dataAll = country?.data?.data?.items?.map((e) => {
    return {
      id: e?.id,
      name: e.name?.ar,
    };
  });

  const dataGender = [
    {
      id: "MALE",
      name: "ذكر",
    },
    {
      id: "FEMALE",
      name: "أنثى",
    },
  ];

  const genders = dataGender.map((e) => {
    return {
      id: e?.id,
      name: e.name,
    };
  });

  useEffect(() => {
    id !== "add" &&
      setFormData({
        ...formData,
        _method: "PUT",
        name_ar: dataOld?.name?.ar,
        name_en: dataOld?.name?.en,
        parent_item_id: dataOld?.parent_item_id,
      });
  }, [dataOld, country]);

  return (
    <div>
      <Container>
        <Col lg={8} md={10} className="mx-auto">
          <Row justify="center" className="bg-white shadow-sm rounded-2xl p-5">
            <TitleEdit />
            {rout === "cities" && (
              <Col>
                <MultipleSelect
                  name="parent_item_id"
                  text={content.Countries}
                  value={formData?.parent_item_id}
                  options={dataAll}
                  onChange={handleChangeSelect}
                />
              </Col>
            )}
            {rout === "jobs" && (
              <Col>
                <MultipleSelect
                  name="job_id"
                  text={content.Jobs}
                  value={formData?.job_id}
                  options={dataAll}
                  onChange={handleChangeSelect}
                />
              </Col>
            )}
            {rout === "socials-statuses" && (
              <Col>
                <MultipleSelect
                  name="type"
                  text={content.Gender}
                  value={formData?.type}
                  options={genders}
                  onChange={handleChangeSelect}
                />
              </Col>
            )}
            {rout === "marriage-statuses" && (
              <Col>
                <MultipleSelect
                  name="type"
                  text={content.Gender}
                  value={formData?.type}
                  options={genders}
                  onChange={handleChangeSelect}
                />
              </Col>
            )}
            <Col>
              <Input
                name="name_ar"
                onChange={handleChangeInput}
                placeholder={content?.NameAr}
                value={formData?.name_ar}
              />
            </Col>
            <Col>
              <Input
                name="name_en"
                onChange={handleChangeInput}
                placeholder={content?.NameEn}
                value={formData?.name_en}
              />
            </Col>
            {loading || (id !== "add" && isLoading) ? <Loading /> : ""}
            <Back name="حفظ" onClick={handleSubmitMain} />
          </Row>
        </Col>
      </Container>
    </div>
  );
}
export default AddNationalities;
