import { MdAdminPanelSettings, MdOutlinePassword } from "react-icons/md";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";
import { useContextHook } from "../../Context/ContextOPen";
import Help from "../../Svg/Help.svg";
import Report from "../../Svg/Report.svg";
import MonthlyIncomes from "../../Svg/Salaries.svg";
import Support from "../../Svg/Support.svg";
import UserSitting from "../../Svg/User sitting.svg";
import Users from "../../Svg/Users.svg";
import beard from "../../Svg/beard.svg";
import Blog from "../../Svg/blogs.svg";
import prayer from "../../Svg/carpet.svg";
import faq from "../../Svg/faq.svg";
import Ads from "../../Svg/megaphone_2597229.svg";
import hijab from "../../Svg/hijab.svg";
import marriageStatuses from "../../Svg/marriage-case.svg";
import smoking from "../../Svg/smoking-rooms.svg";
import employmentStatuses from "../../Svg/work (1).svg";
import { logout, useFETCH } from "../../Tools/APIs/useMyAPI";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Accordion, Popup } from "../../components";
import SocialSituations from "../../Svg/Vector (2).svg";
import SkinColor from "../../Svg/Vector (3).svg";
import Physique from "../../Svg/Vector (4).svg";
import Commitment from "../../Svg/Vector (5).svg";
import Degrees from "../../Svg/Vector (6).svg";
import Finance from "../../Svg/Vector (7).svg";
import HealthStatus from "../../Svg/Vector (8).svg";
import Nationalities from "../../Svg/Vector (9).svg";
import Countries from "../../Svg/Vector.svg";
import logo from "../../images/logo.png";
import logoutIcon from "../../Svg/logout.svg";
import "./SideBar.css";
import { useEffect, useState } from "react";
import { BiPackage } from "react-icons/bi";

function SideBar() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const { data: user } = useFETCH("admin/profile");
  const role = user?.data?.data?.role?.name;

  useEffect(() => {
    if (role == "super_admin") setIsSuperAdmin(true);
  }, [role]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  const changeMenu = () => {
    setOpenMenu(true);
  };
  const { openMenu, setOpenMenu } = useContextHook();
  if (windowWidth >= 1024) changeMenu();

  const { content } = useContextTranslate();
  const sidebar = [
    // { image: Home, name: content.Home, link: "/home" },
    { image: Users, name: content.Users, link: "/users" },
    ...(isSuperAdmin
      ? [{ image: MdAdminPanelSettings, name: content.Admins, link: "/admin" }]
      : []),

    { name: content.UserSitting, link: "/user-sitting" },
    { image: Blog, name: content.Blog, link: "/blog-sections" },
    { image: Help, name: content.Help, link: "/support" },
    { image: Support, name: content.SupportMessage, link: "/supports" },
    {
      image: faq,
      name: content.CommonQuestions,
      link: "/common-questions",
    },
    {
      image: BiPackage,
      name: content.packages,
      link: "/packages",
    },
    { image: Report, name: content.Report, link: "/report" },
    { image: Ads, name: content.Advertisement, link: "/advertisement" },
    {
      image: MdOutlinePassword,
      name: content.ChangePassword,
      link: "/Change-Password",
    },
    {
      image: AiOutlineExclamationCircle,
      name: content.generalInfo,
      link: "/general-information",
    },
  ];
  const List = [
    {
      image: Nationalities,
      name: content.Nationalities,
      link: "/nationalities",
    },
    { image: Countries, name: content.Countries, link: "/countries" },
    // { image: Countries, name: content.Cities, link: "/cities" },
    {
      image: SocialSituations,
      name: content.SocialSituations,
      link: "/socials-statuses",
    },
    { image: SkinColor, name: content.SkinColors, link: "/skin-colors" },
    { image: Physique, name: content.Physique, link: "/physiques-statuses" },
    {
      image: Commitment,
      name: content.Commitment,
      link: "/religiosity-statuses",
    },
    {
      image: prayer,
      name: content.prayerStatuses,
      link: "/prayer-statuses",
    },
    {
      image: hijab,
      name: content.hijabStatuses,
      link: "/hijab-statuses",
    },
    {
      image: smoking,
      name: content.smokingStatuses,
      link: "/smoking-statuses",
    },
    {
      image: beard,
      name: content.beardStatuses,
      link: "/beard-statuses",
    },
    {
      image: marriageStatuses,
      name: content.MarriageStatuses,
      link: "/marriage-statuses",
    },
    {
      image: MonthlyIncomes,
      name: content.MonthlyIncomes,
      link: "/monthly-income-ranges",
    },
    {
      image: employmentStatuses,
      name: content.employmentStatuses,
      link: "/employment-statuses",
    },
    { image: Finance, name: content.Finance, link: "/financial-statuses" },
    { image: Degrees, name: content.Degrees, link: "/educational-levels" },
    {
      image: HealthStatus,
      name: content.HealthStatus,
      link: "/healths-statuses",
    },
  ];
  return (
    <>
      <div
        className={`${
          openMenu
            ? "!w-[220px] max-sm:!w-[250px] !max-lg:translate-x-10 !max-lg:w-3/4 -!right-10"
            : " w-[0px]"
        } !max-lg:translate-x-full bg-white sidebar lg:h-[95vh] h-[100vh] mt-0 lg:mt-5 lg:rounded-2xl sideBar max-lg:h-[100%] sticky max-lg:fixed lg:left-10 left-0 lg:right-10 right-0 top-0 transition-all overflow-y-auto py-4 z-40 max-lg:z-50 `}
      >
        <div className="px-3">
          <ul className="text-white font-semibold space-y-2">
            <li className="">
              <Link to="/">
                <img src={logo} alt="" className="m-2 mx-auto object-contain" />
              </Link>
            </li>

            {sidebar.map((e, i) => (
              <li className="" key={i}>
                {e.name === content.UserSitting ? (
                  <Accordion
                    list={List}
                    image={UserSitting}
                    name={content.UserSitting}
                  />
                ) : (
                  <NavLink
                    to={e.link}
                    className="flex items-center gap-2 py-2 px-2 rounded-lg text-sm text-[#6F757E] hover:bg-Main hover:text-white transition-all"
                  >
                    {e.image === MdAdminPanelSettings ||
                    e.image === MdOutlinePassword ||
                    e.image === AiOutlineExclamationCircle ||
                    e.image === AiOutlineExclamationCircle ||
                    e.image === BiPackage ? (
                      <e.image size={20} className="" />
                    ) : (
                      <img src={e.image} alt="" className="w-[20px] h-[20px]" />
                    )}
                    <p>{e.name}</p>
                  </NavLink>
                )}
              </li>
            ))}
            <li>
              <Popup
                id={`logout`}
                text="تسجيل خروج"
                description="هل انت متأكد من تسجيل الخروج ؟"
                onClick={() => logout("admin/logout")}
                hover={true}
              >
                <div className="flex justify-center items-center gap-2 cursor-pointer">
                  <img src={logoutIcon} alt="" />
                  <p className="text-Secondary">{content.Logout}</p>
                </div>
              </Popup>
            </li>
          </ul>
        </div>
      </div>
      <div
        onClick={() => setOpenMenu(!openMenu)}
        style={{ transition: "0.4s" }}
        className={`${
          openMenu ? "" : "hidden"
        }  max-lg:w-full h-full bg-black/20 fixed -left-0 top-0 transition-all z-40`}
      />
    </>
  );
}

export default SideBar;
