import React, { useEffect, useRef } from "react";
import ReactPaginate from "react-paginate";
import { Row } from "../Grid-system";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { useFilter } from "../APIs/useMyAPI";
import { LoadingProduct } from "../../components";

const Pagination = ({ pageCount, children, isLoading }) => {
  const { handlePageClick, searchParams } = useFilter();
  const { content } = useContextTranslate();
  const a = useRef();
  useEffect(() => {
    if (a.current) {
      a.current.state.selected = searchParams.get("page")
        ? searchParams.get("page") - 1
        : 0;
    }
  }, [searchParams.get("page")]);
  return (
    <>
      <div>
        {isLoading ? (
          <Row>
            <LoadingProduct />
            <LoadingProduct />
            <LoadingProduct />
            <LoadingProduct />
            <LoadingProduct />
          </Row>
        ) : (
          <div>{children}</div>
        )}
      </div>
      {pageCount < 1 && (
        <p className="font-semibold text-center">{content.NoData}</p>
      )}
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          ref={a}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
    </>
  );
};

export default Pagination;
