import { CiSearch } from "react-icons/ci";

import Filter from "./Filter";
import { useClose, useFilter } from "../../Tools/APIs/useMyAPI";
import moment from "moment/moment";

const DateFilter = ({ now, title, pushSearch, hideAll }) => {
  const { open, setOpen, mouse } = useClose();
  const { searchParams, handleParamsClick, handleParamsDelete } = useFilter();
  const week = moment().subtract(10, "days").format(`YYYY-MM-DD`);
  const month = `${new Date().getFullYear()}-${new Date()
    .getMonth()
    .toString()
    .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`;

  return (
    <>
      <div ref={mouse} className="relative">
        <div
          className={`w-full h-[49px]  text-base max-sm:mx-auto max-md:text-base bg-white rounded-xl text-center shadow-Third shadow-sm font-bold flex justify-center gap-4 items-center py-3 px-1 text-Third`}
          onClick={() => setOpen(!open)}
        >
          {title ? title : "  التاريخ"}
          <CiSearch size={20} />
        </div>

        <div
          className={`${
            open
              ? "absolute w-full top-12 transition-all border-2 border-Third   z-20  bg-white rounded-xl text-center   text-Third  mt-1"
              : "hidden"
          }`}
        >
          {hideAll ? (
            ""
          ) : (
            <Filter
              onclick={() => {
                handleParamsDelete("start_date");
                handleParamsDelete("end_date");
                if (pushSearch) {
                  handleParamsDelete(pushSearch);
                }
              }}
              text="الكل"
            ></Filter>
          )}

          <Filter
            classNameBut={
              searchParams.get("start_date") === week
                ? "bg-Third text-white"
                : ""
            }
            onclick={() => {
              handleParamsClick("start_date", week);
              handleParamsDelete("end_date");
              if (pushSearch) {
                handleParamsClick(pushSearch, 1);
              }
            }}
            text="الاسبوع"
          />
          <Filter
            classNameBut={
              searchParams.get("start_date") === month
                ? "bg-Main text-white"
                : ""
            }
            onclick={() => {
              handleParamsClick("start_date", month);
              handleParamsDelete("end_date");
              if (pushSearch) {
                handleParamsClick(pushSearch, 1);
              }
            }}
            text="الشهر"
          />
          <Filter
            classNameBut={searchParams.get("end_date") ? "bg-Main" : ""}
            onclick={() => console.log()}
            text="تاريخ محدد"
          >
            <div
              md={5}
              className="flex justify-center items-center flex-col gap-5 py-2"
            >
              <div className="border border-white p-2 rounded-xl relative flex items-center justify-center ">
                <span className="  text-Third -top-3 px-2 ">تاريخ البداية</span>
                <input
                  type="date"
                  className="bg-transparent text-Third"
                  value={searchParams.get("start_date")}
                  onChange={(e) => {
                    handleParamsClick("start_date", e.target.value);
                    if (pushSearch) {
                      handleParamsClick(pushSearch, 1);
                    }
                  }}
                />
              </div>
              <div className="border border-white p-2 rounded-xl relative flex items-center justify-center">
                <span className=" -top-3 text-Third px-2 ">تاريخ النهاية</span>
                <input
                  className="bg-transparent text-Third"
                  type="date"
                  value={searchParams.get("end_date")}
                  onChange={(e) => {
                    handleParamsClick("end_date", e.target.value);
                    if (pushSearch) {
                      handleParamsClick(pushSearch, 1);
                    }
                  }}
                />
              </div>
            </div>
          </Filter>
        </div>
      </div>
    </>
  );
};

export default DateFilter;
