import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Back, Input, TitleEdit } from "../../components";
function AddCities({ type }) {
  const { content } = useContextTranslate();
  const { id, idd } = useParams();
  const { handleChangeInput, handleSubmit, setFormData, formData, loading } =
    usePOST({ parent_item_id: id, type: "*" });
  const { data, isLoading } = useFETCH(
    idd === "add" ? "" : `admin/lists/${type}/items/${idd}`
  );
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      idd === "add" ? `admin/lists/${type}` : `admin/lists/${type}/items/${idd}`
    );
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    idd !== "add" &&
      setFormData({
        ...formData,
        _method: "PUT",
        name_ar: dataOld?.name?.ar,
        name_en: dataOld?.name?.en,
      });
  }, [dataOld]);
  return (
    <div>
      <Container>
        <Col md={6} className="mx-auto">
          <Row justify="center" className="bg-white shadow-sm rounded-2xl p-5">
            <TitleEdit />
            <Col>
              <Input
                name="name_ar"
                onChange={handleChangeInput}
                placeholder={content?.NameAr}
                value={formData?.name_ar}
              />
            </Col>
            <Col>
              <Input
                name="name_en"
                onChange={handleChangeInput}
                placeholder={content?.NameEn}
                value={formData?.name_en}
              />
            </Col>
            {loading || (idd !== "add" && isLoading) ? <Loading /> : ""}
            <Back name="حفظ" onClick={handleSubmitMain} />
          </Row>
        </Col>
      </Container>
    </div>
  );
}
export default AddCities;
