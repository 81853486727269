const Input = ({
  type,
  name,
  value,
  onChange,
  placeholder,
  title,
  hideTitle,
  className,
  error,
  onKeyDown,
  defaultValue,
}) => {
  return (
    <div className="py-3">
      <h1
        className={`${
          hideTitle ? "hidden" : ""
        } px-3 font-semibold text-start mx-4 mb-2`}
      >
        {placeholder || title}
      </h1>
      {value ? (
        <input
          type={type || "text"}
          name={name}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          placeholder={placeholder}
          className={`border bg-[#fff] border-[#D2D2D2] m-1 py-[0.4rem] px-2 rounded-md ${className}`}
          onKeyDown={onKeyDown}
        />
      ) : (
        <input
          type={type || "text"}
          name={name}
          defaultValue={defaultValue}
          onChange={onChange}
          placeholder={placeholder}
          className={`border bg-[#fff] border-[#D2D2D2] m-1 py-[0.4rem] px-2 rounded-md ${className}`}
          onKeyDown={onKeyDown}
        />
      )}

      {error && <div className="text-red-500 font-semibold">{error}</div>}
    </div>
  );
};
export default Input;
