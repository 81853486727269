import { useParams } from "react-router-dom";
import { clickZoomInImage, fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Data, Title } from "../../components";
import userDef from "../../images/icon-user-default.png";
import { useContextHook } from "../../Context/ContextOPen";

function UserDetails() {
  const { setShowUserImages } = useContextHook();
  const { id } = useParams();
  const { data, isLoading } = useFETCH(`admin/users/${id}`);
  let dataOld = data?.data.data;

  return (
    <div>
      <Container>
        <Title title="معلومات المستخدم" />
        <Row className="border border-Main rounded-2xl p-5 items-center">
          <Col>
            {isLoading ? (
              <div className="rounded-full bg-slate-300 animate-pulse object-cover h-[120px] !w-[120px] mx-auto" />
            ) : (
              <img
                src={
                  dataOld.images.length > 0
                    ? fileUrl + dataOld.images[dataOld.images.length - 1].image
                    : userDef
                }
                alt=""
                className="cursor-pointer rounded-full object-cover h-[120px] !w-[120px] mx-auto"
                onClick={() => {
                  if (dataOld?.images?.length > 0)
                    setShowUserImages([id, true]);
                  else return;
                }}
              />
            )}
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="الاسم"
              text={dataOld?.secret_data?.name}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="اسم المستخدم"
              text={dataOld?.login_details?.username}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="الإيميل"
              text={dataOld?.login_details?.email}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="رقم الهاتف"
              text={dataOld?.secret_data?.phone_number}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="الجنس"
              text={dataOld?.gender === "MALE" ? "ذكر" : "أنثى"}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="الطول"
              text={dataOld?.my_specifications?.height}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="الوزن"
              text={dataOld?.my_specifications?.weight}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="العمر"
              text={dataOld?.social_status?.age}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="عدد الأطفال"
              text={dataOld?.social_status?.children}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="الجنسية"
              text={dataOld?.nationality_residence?.nationality.name}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="البلد"
              text={dataOld?.nationality_residence?.country.name}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="المدينة"
              text={dataOld?.nationality_residence?.city?.name}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="الإلتزام"
              text={dataOld?.religious_commitment?.religiosity_status?.name}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="حالة الصلاة"
              text={dataOld?.religious_commitment?.prayer_status?.name}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name={dataOld?.gender === "MALE" ? "حالة اللحية" : "حالة الحجاب"}
              text={
                dataOld?.gender === "MALE"
                  ? dataOld?.religious_commitment?.beard_status?.name
                  : dataOld?.religious_commitment?.hijab_status?.name
              }
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="حالة التوظيف"
              text={dataOld?.study_work?.employment_status?.name}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="العمل"
              text={dataOld?.study_work?.job?.name}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="الدخل الشهري"
              text={dataOld?.study_work?.monthly_income_range?.name}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="الحالة المادية"
              text={dataOld?.study_work?.financial_status?.name}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="الحالة الإجتماعية"
              text={dataOld?.social_status?.social_status?.name}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="الحالة الصحية"
              text={dataOld?.study_work?.health_status?.name}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="حالة الدخان"
              text={dataOld?.religious_commitment?.smoking_status?.name}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="لون البشرة"
              text={dataOld?.my_specifications?.skin_color?.name}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="المستوى الدراسي"
              text={dataOld?.study_work?.educational_level?.name}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="البنية الجسدية"
              text={dataOld?.my_specifications?.physique_status?.name}
            />
          </Col>
          <Col md={4} xs={6}>
            <Data
              load={isLoading}
              name="حالة الزواج"
              text={dataOld?.social_status?.marriage_status?.name}
            />
          </Col>
          <Col md={6}>
            <Data load={isLoading} name="موصفاتي" text={dataOld?.own_props} />
          </Col>
          <Col md={6}>
            <Data
              load={isLoading}
              name="موصفات شريكي"
              text={dataOld?.future_wife_props}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default UserDetails;
