import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Loading from "../../Tools/Loading";

const VisitorsChart = ({ data, isLoading }) => {
  if (isLoading) return <Loading />;

  const statistics =
    data &&
    data?.views_and_clicks?.map((e, index) => ({
      date: e.date,
      views_count: parseInt(e.views_count),
      clicks_count: parseInt(e.clicks_count),
    }));

  const legendFormatter = (value) => {
    switch (value) {
      case "views_count":
        return "عدد المشاهدات"; // Arabic label for views
      case "clicks_count":
        return "عدد الزيارات"; // Arabic label for clicks
      default:
        return value;
    }
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const viewsCount = payload[0].value;
      const clicksCount = payload[1].value;
      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "10px",
            border: "1px solid #ccc",
          }}
          dir="rtl"
        >
          <p className="flex gap-2">
            <span>عدد المشاهدات:</span>
            <span className="text-[#14532d]">{viewsCount}</span>
          </p>{" "}
          <p className="flex gap-2">
            <span>عدد الزيارات:</span>
            <span className="text-[#16a34a]">{clicksCount}</span>
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    !isLoading && (
      <ResponsiveContainer minWidth={1000} width="100%" height={450}>
        <BarChart
          barGap={-25}
          width="100%"
          height={450}
          data={statistics}
          style={{}}
        >
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend formatter={legendFormatter} />
          <Bar
            label="عدد المشاهدات"
            dataKey="views_count"
            barSize={30}
            fill="#14532d"
          />
          <Bar
            label="عدد الزيارات"
            dataKey="clicks_count"
            fill="#16a34a"
            barSize={20}
          />
        </BarChart>
      </ResponsiveContainer>
    )
  );
};

export default VisitorsChart;
