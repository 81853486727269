import { Link, useLocation, useParams } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import {
  Add,
  MainTitle,
  ReportFilter,
  SuccessMessage,
  Table,
} from "../../components";
function Report() {
  const { content } = useContextTranslate();
  const { search } = useLocation();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/reports${search}`,
    `admin/reports`
  );

  const dataAll = data?.data?.data?.data?.map((e) => {
    return {
      id: e?.id,
      reported_by: e?.reported_by,
      accused_id: e?.accused_id,
      accused: e?.accused?.name,
      reporter: e?.reporter?.name,
      accused_message: e?.accused_message?.content,
      date: e?.created_at?.substring(0, 10),
      reason: e?.reason,
    };
  });
  return (
    <div>
      <MainTitle title={content.Report} />
      <Container>
        <ReportFilter />
        <Row justify="between">
          <Col lg={10} className="bg-white shadow-sm rounded-2xl p-5 mx-auto">
            <Add titles={content.Report} hidBtn={true} />
            <Table
              thead={[
                content.Id,
                content.Accused,
                content.Reporter,
                content.AccusedMessage,
                content.Date,
                content.Reason,
              ]}
              pageCount={Math.ceil(
                data?.data.data?.total / data?.data.data?.per_page
              )}
              tData={dataAll}
              tbody={[
                "id",
                "accused",
                "reporter",
                "accused_message",
                "date",
                "reason",
              ]}
              funDelete={deleteItem}
              isLoading={isLoading}
              show="chat"
              replayed="true"
              isAdmin={true}
              isChat={true}
            />
          </Col>
        </Row>
        <SuccessMessage send={true} />
      </Container>
    </div>
  );
}
export default Report;
