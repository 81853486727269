import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Add, MainTitle, Table } from "../../components";
function Admin() {
  const { data: user } = useFETCH("admin/profile");
  const isAdmin = user?.data?.data?.role?.name === "super_admin";
  const { content } = useContextTranslate();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/admins`,
    `admin/admins`
  );
  const dataAll = data?.data?.data?.data?.map((e) => {
    return {
      id: e?.id,
      name: e.name,
      gender:
        e.gender === "MALE" ? "ذكر" : e.gender === "FEMALE" ? "أنثى" : "___",
      username: e.username,
      email: e.email,
    };
  });
  return (
    <div>
      <MainTitle title={content.Admins} />
      <Container>
        <Row justify="between">
          <Col lg={10} className="bg-white shadow-sm rounded-2xl p-5 mx-auto">
            <Add
              hidBtn={!isAdmin}
              link={`/admin/add`}
              titles={content.Admins}
              title={content.Admin}
            />
            <Table
              hide={!isAdmin}
              thead={[
                content.Id,
                content.Name,
                content.Gender,
                content.Username,
                content.Email,
              ]}
              pageCount={Math.ceil(
                data?.data.data?.total / data?.data.data?.per_page
              )}
              tData={dataAll}
              tbody={["id", "name", "gender", "username", "email"]}
              funDelete={deleteItem}
              edit={`admin`}
              isLoading={isLoading}
              isAdmin={isAdmin}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Admin;
