import { useEffect, useState } from "react";
import { useFETCH } from "../Tools/APIs/useMyAPI";

const useItemsData = (name, id) => {
  const { data } = useFETCH(
    id !== "undefined" && id && name
      ? `admin/lists/${name}?type=*&parent_item_id=${id}`
      : ""
  );
  const [itemsData, setItemsData] = useState(null);
  
  useEffect(() => {
    setItemsData(data?.data?.data?.items);
  }, [data]);
  return { itemsData };
};

export default useItemsData;
