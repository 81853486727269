import { useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Back, TitleEdit } from "../../components";
import { useState } from "react";
import Select from "react-select";

function AddPosition() {
  const { content } = useContextTranslate();
  const { id } = useParams();
  const { handleSubmit, loading, setCheckArray } = usePOST({});
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(`admin/ads/${id}/positions`);
  };
  const { data } = useFETCH(`admin/ads/${id}/positions/names/list`);
  const [selectPosition, setSelectPosition] = useState([]);
  const positionsOption = data?.data?.data?.map((e) => {
    return {
      value: e.name,
      label: e.display + " ( الأبعاد : " + e.dimensions + " ) ",
    };
  });
  return (
    <div>
      <Container>
        <Col lg={8} md={10} className="mx-auto">
          <Row justify="center" className="bg-white shadow-sm rounded-2xl p-5">
            <TitleEdit />
            <Col>
              <h1 className="text-start mb-2">أضف مكان إعلان</h1>
              <Select
                closeMenuOnSelect={false}
                isMulti
                name="positions_names[]"
                value={selectPosition}
                onChange={(selectedOptions) => {
                  const selectedValues = selectedOptions?.map((option) => [
                    option.value,
                  ]);
                  setSelectPosition(selectedOptions);
                  setCheckArray({ "positions_names[]": selectedValues });
                }}
                options={positionsOption}
              />
            </Col>
            {loading ? <Loading /> : ""}
            <Back name="حفظ" onClick={handleSubmitMain} />
          </Row>
        </Col>
      </Container>
    </div>
  );
}
export default AddPosition;
