import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

const SliderMUI = ({ name, max, min, unit, title, value, onChange, error }) => {
  return (
    <>
      <Box className="flex items-center justify-center gap-3 mt-6">
        <div className="px-3 font-semibold">{title}</div>
        <div className="mt-3 flex-1">
          <Slider
            valueLabelDisplay="on"
            valueLabelFormat={(value) => value + " " + unit}
            max={max}
            min={min}
            sx={{ color: "#d86aaa" }}
            value={value ? value : null}
            name={name}
            onChange={onChange}
          />
        </div>
      </Box>
      {error && <div className="text-red-500 font-semibold">{error}</div>}
    </>
  );
};

export default SliderMUI;

