import { useContextTranslate } from "../../Translate/ContextTranslate";
import parse from "html-react-parser";
import Popup from "../Popup/Popup";

function PopupTable({ text }) {
  const { content } = useContextTranslate();

  return (
    <div>
      <Popup
        hide={true}
        hover={true}
        description={parse(text)}
        className="min-w-[200px]"
        desClass="text-lg"
      >
        <div className="text-sm cursor-pointer">
          <p className="w-20 mx-auto h-5 overflow-hidden">{parse(text)} </p>
          <p className="text-[12px] text-Third">{content.ReadMore}</p>
        </div>
      </Popup>
    </div>
  );
}
export default PopupTable;
