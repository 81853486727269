import { useEffect, useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { useContextHook } from "../../Context/ContextOPen";
import userDef from "../../images/icon-user-default.png";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import Pagination from "../../Tools/Pagination";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import IconHover from "../IconHover/IconHover";
import Delete from "../Operation/Delete";
import Replayed from "../Operation/Replayed";
import Show from "../Operation/Show";
import Update from "../Operation/Update";
import Popup from "../Popup/Popup";
import { Link } from "react-router-dom";
import { BiPackage } from "react-icons/bi";

const Table = ({
  isAdmin,
  thead,
  tbody,
  tData,
  show,
  showUser,
  funDelete,
  pageCount,
  edit,
  hide,
  showContent,
  showCountry,
  isLoading,
  clicks,
  position,
  userId,
  report,
  replayed,
  deleteAll,
  packages,
  isAds = false,
  isChat = false,
}) => {
  const [gitId, setGitId] = useState();
  const { setShowUserImages } = useContextHook();
  const { data } = useFETCH(gitId && `admin/users/${gitId}/token`);
  let token = data?.data?.data?.token;
  const { content } = useContextTranslate();
  const [isDeleteAll, setIsDeleteAll] = useState(false);
  const allAdsIds = isAds ? tData?.map((e) => e.id) : "";
  const [adsIds, setAdsIds] = useState([]);

  const handlChangeCheck = (e, id) => {
    if (e.target.checked) setAdsIds((prev) => [...prev, id]);
    else setAdsIds((prev) => prev.filter((e) => e !== id));
  };

  const handleDeleteAll = () => {
    if (allAdsIds && !isDeleteAll) setAdsIds(allAdsIds);
    else setAdsIds([]);
  };

  useEffect(() => {
    if (token)
      window.location.assign("https://qiranbook.com/#/admin/sign-in/" + token);
  }, [token]);

  useEffect(() => {
    if (data) token = data?.data?.data?.token;
  }, [data]);

  return (
    <div>
      <Pagination isLoading={isLoading} pageCount={pageCount}>
        <div className="mx-auto w-full py-4 overflow-x-auto h-full pb-5">
          <table className="w-full mx-auto border overflow-hidden rounded-2xl h-full bg-white">
            <thead className="w-full text-center">
              <tr>
                {deleteAll === true ? (
                  <th>
                    <input
                      type="checkbox"
                      onClick={() => {
                        setIsDeleteAll(!isDeleteAll);
                        handleDeleteAll();
                      }}
                    />
                  </th>
                ) : (
                  ""
                )}
                {thead?.map((e, i) => (
                  <th key={i} className="py-3 px-5 text-sm font-semibold">
                    {e}
                  </th>
                ))}
                <td className={`${hide ? "hidden" : "p-5 w-fit"}`}>
                  {content.Action}
                </td>
                {isDeleteAll && (
                  <td className="w-[150px]">
                    <Popup
                      btnClassName="py-2 px-1 rounded-xl"
                      description="هل انت متأكد من حذف كل الإعلانات؟"
                      onClick={() => {
                        funDelete("", "bulk?ads_ids=" + adsIds.join(", "));
                        setIsDeleteAll(false);
                      }}
                    >
                      حذف الكل
                    </Popup>
                  </td>
                )}
              </tr>
            </thead>
            <tbody className=" text-center">
              {tData &&
                tData?.map((e, i) => (
                  <tr key={i}>
                    {tbody?.map((name, i) => (
                      <>
                        {!name ? (
                          <td key={i} className={`py-1 px-2 text-[#8C9198]`}>
                            <input
                              id={e.id}
                              type="checkbox"
                              {...(isDeleteAll
                                ? { checked: { isDeleteAll } }
                                : {})}
                              onChange={(event) =>
                                handlChangeCheck(event, e.id)
                              }
                            />
                          </td>
                        ) : (
                          <td key={i} className={`py-1 px-2 text-[#8C9198]`}>
                            {typeof e[name] === "string" &&
                            e[name].substring(0, 4) === "http" ? (
                              <img
                                src={e[name]?.length > 4 ? e[name] : userDef}
                                alt=""
                                className="w-14 h-14 rounded-full mx-auto cursor-pointer"
                                onClick={() => {
                                  if (userId) setShowUserImages([e.id, true]);
                                }}
                              />
                            ) : e[name] ? (
                              <div>{e[name]}</div>
                            ) : (
                              "___"
                            )}
                          </td>
                        )}
                      </>
                    ))}
                    <td
                      className={`${
                        hide ? "hidden" : "cursor-pointer relative"
                      }`}
                    >
                      <div className="flex justify-center items-center gap-3">
                        {edit && isAdmin && (
                          <Update edit={`/${edit}/${e.id}`} />
                        )}
                        {replayed && <Replayed id={e.id} />}
                        {show ? (
                          isChat ? (
                            <Show
                              show={`/${show}${
                                "?accused_id=" +
                                e.accused_id +
                                "&reported_by=" +
                                e.reported_by
                              }`}
                              text={showContent}
                            />
                          ) : (
                            <Show
                              show={`/${show}/${e.id}`}
                              text={showContent}
                            />
                          )
                        ) : (
                          ""
                        )}
                        {report && (
                          <Show
                            id="report"
                            show={`/report?accused_id=${e.id}`}
                            text="عرض الإبلاغات"
                            report="true"
                          />
                        )}
                        {showCountry && (
                          <Show
                            id="country"
                            show={`/${showCountry}/${e.id}`}
                            text="عرض الدول الخاصة بهذا الإعلان"
                            countries="true"
                          />
                        )}
                        {clicks && (
                          <Show
                            id="clicks"
                            show={`/${clicks}/${e.id}`}
                            text="عرض تقرير النقرات"
                            click={true}
                          />
                        )}
                        {position && (
                          <Show
                            id="position"
                            show={`/${position}/${e.id}`}
                            text="عرض أماكن الإعلانات"
                            position={true}
                          />
                        )}
                        {userId && (
                          <div onClick={() => setGitId(e.id)}>
                            <IconHover
                              id="user"
                              text="تسجيل دخول باسم هذا المستخدم"
                            >
                              <div
                                className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                              >
                                <FaUserAlt size={20} className="text-Third" />
                              </div>
                            </IconHover>
                          </div>
                        )}
                        {packages && (
                          <Link to={`/packages?user_id=${e.id}`}>
                            <IconHover id="packages" text="باقات المستخدم">
                              <div
                                className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                              >
                                <BiPackage size={20} className="text-black" />
                              </div>
                            </IconHover>
                          </Link>
                        )}
                        {showUser && (
                          <Show
                            id="package"
                            show={`/users?package_id=${e.id}`}
                            text="عرض المستخدمين"
                            isPackage={true}
                          />
                        )}
                        {funDelete && isAdmin && (
                          <Delete deleteClick={() => funDelete(e)} />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Pagination>
    </div>
  );
};

export default Table;
