import React, { useEffect } from "react";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Add, MainTitle, Table } from "../../components";
import map from "../../images/map.png";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { useLocation, useNavigate } from "react-router-dom";

export const FEATURES = [
  {
    key: "CHAT",
    name: "الدردشة",
  },
  {
    key: "PROFILE_VISITORS",
    name: "رؤية من زار بروفايلي",
  },
  {
    key: "CARES",
    name: "القدرة على اهتمام بشخص ما",
  },
  {
    key: "CHAT_NATIONALTIES",
    name: "القدرة على تحديد الجنسيات التي تراسلني",
  },
  {
    key: "CHAT_COUUNTRIES",
    name: "القدرة على تحديد البلدان التي تراسلني",
  },
  {
    key: "MANAGE_NOTIFICATIONS",
    name: "التحكم بالاشعارات التي تصلني",
  },
];

export default function Packages() {
  const { content } = useContextTranslate();
  const { search } = useLocation();
  const { deleteItem, data, isLoading } = useFETCH(
    `admin/packages${search}`,
    `admin/packages`
  );
  const dataAll = data?.data.data.map((pack) => ({
    ...pack,
    features: pack.features
      .spilt(",")
      .map((feature) => FEATURES.find((f) => f.key === feature).name),
  }));

  return (
    <div>
      <MainTitle title={"الباقات"} />
      <Container>
        {" "}
        <Row justify="between">
          <Col lg={10} className="bg-white shadow-sm rounded-2xl p-5 mx-auto">
            <Add
              titles={content.Categories}
              title={content.Category}
              link="/packages/add"
            />
            <Table
              thead={[
                content.Id,
                "الميزات",
                "المدة",
                "السعر القديم",
                "السعر الجديد",
              ]}
              pageCount={1}
              showUser
              tData={dataAll}
              tbody={["id", "features", "duration", "old_price", "new_price"]}
              funDelete={deleteItem}
              edit="packages"
              isLoading={isLoading}
              isAdmin={true}
            />
          </Col>
        </Row>
      </Container>
      ;
    </div>
  );
}
