import { useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { useContextTranslate } from "../../Translate/ContextTranslate";

function PasswordInput({
  onChange,
  value,
  name,
  error,
  className,
  hideTitle,
  top,
  placeholder,
  onKeyDown,
}) {
  const [biShow, setBiShow] = useState(false);
  const { content } = useContextTranslate();
  return (
    <div className="">
      <h1
        className={`${
          hideTitle ? "hidden" : ""
        } px-3 font-semibold text-start mx-4 mb-2`}>
        {placeholder || content.Password}
      </h1>
      <div className="relative">
        <input
          type={!biShow ? "password" : "text"}
          onChange={onChange}
          value={value}
          name={name}
          autoComplete="new-password"
          placeholder={placeholder || content.Password}
          className={`border bg-[#fff] border-[#D2D2D2] m-1 py-[0.4rem] rounded-md ${className}`}
          onKeyDown={onKeyDown}
        />
        {biShow ? (
          <BiShow
            onClick={() => setBiShow(false)}
            size={22}
            className={`absolute left-3 top-2 cursor-pointer ${top}`}
          />
        ) : (
          <BiHide
            onClick={() => setBiShow(true)}
            size={22}
            className={`absolute left-3 top-2 cursor-pointer ${top}`}
          />
        )}
      </div>
      {error && <div className="text-red-500 font-semibold">{error}</div>}
    </div>
  );
}
export default PasswordInput;
