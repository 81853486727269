import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useContextHook } from "../../Context/ContextOPen";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import AddImage from "../../Tools/AddFile";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import {
  Back,
  Checkbox,
  Input,
  MultipleSelect,
  PasswordInput,
  SliderMUI,
  TextArea,
  TitleEdit,
} from "../../components";

function AddUsers() {
  const navigate = useNavigate();
  const { data: user } = useFETCH("admin/profile");
  const role = user?.data?.data?.role?.name;
  // useEffect(() => {
  //   if (role !== "super_admin") navigate("/users");
  // }, [role]);

  const { id } = useParams();
  const { content } = useContextTranslate();
  const { setMessage } = useContextHook();
  const {
    viewImages,
    setViewImages,
    handleChangeArrayImages,
    setImages,
    formData,
    loading,
    handleChangeInput,
    handleSubmit,
    setFormData,
    handleChangeSelect,
    counter,
    setCounter,
  } = usePOST({ is_active: 0 });
  const { data, isLoading } = useFETCH(id === "add" ? "" : `admin/users/${id}`);

  let dataOld = data?.data.data;

  const { data: city, isLoading: cityLoading } = useFETCH(
    formData?.country_id &&
      `admin/lists/cities?type=*&parent_item_id=${formData?.country_id}`
  );

  useEffect(() => {
    id !== "add" &&
      setFormData({
        ...formData,
        _method: "PUT",
        name: dataOld?.secret_data?.name,
        password: dataOld?.password,
        username: dataOld?.login_details?.username,
        email: dataOld?.login_details?.email,
        height: dataOld?.my_specifications?.height,
        age: dataOld?.social_status?.age,
        city_id: dataOld?.nationality_residence?.city?.id,
        marriage_status_id: dataOld?.social_status?.marriage_status?.id,
        prayer_status_id: dataOld?.religious_commitment?.prayer_status?.id,
        beard_status_id: dataOld?.religious_commitment?.beard_status?.id,
        country_id: dataOld?.nationality_residence?.country?.id,
        employment_status_id: dataOld?.study_work?.employment_status?.id,
        job_id: dataOld?.study_work?.job?.id,
        phone_number: dataOld?.secret_data?.phone_number,
        nationality_id: dataOld?.nationality_residence?.nationality?.id,
        marriage_type: dataOld?.marriage_type,
        children: dataOld?.social_status?.children,
        social_status_id: dataOld?.social_status?.social_status?.id,
        smoking_status_id: dataOld?.religious_commitment?.smoking_status?.id,
        religiosity_status_id:
          dataOld?.religious_commitment?.religiosity_status?.id,
        skin_color_id: dataOld?.my_specifications?.skin_color?.id,
        length: dataOld?.length,
        weight: dataOld?.my_specifications?.weight,
        health_status_id: dataOld?.study_work?.health_status?.id,
        educational_level_id: dataOld?.study_work?.educational_level?.id,
        hijab_status_id: dataOld?.religious_commitment?.hijab_status?.id,
        gender: dataOld?.gender,
        monthly_income_range_id: dataOld?.study_work?.monthly_income_range?.id,
        physique_status_id: dataOld?.my_specifications?.physique_status?.id,
        financial_status_id: dataOld?.study_work?.financial_status?.id,
        is_active: dataOld?.is_active ? 1 : 0,
        own_props: dataOld?.own_props,
        future_wife_props: dataOld?.future_wife_props,
      });
  }, [dataOld, city]);

  const [userImages, setUserImages] = useState(dataOld?.images);

  useEffect(() => {
    setUserImages(dataOld?.images);
  }, [dataOld?.images]);

  const handleSubmitMain = (e) => {
    e.preventDefault();
    if (formData?.passwordConfirmation === formData?.password) {
      handleSubmit(id === "add" ? `admin/users` : `admin/users/${id}`);
    } else {
      setMessage(["كلمة المرور وتأكيد كلمة المرور غير متطابقتين"]);
    }
  };
  const { data: inputs, isLoading: load } = useFETCH(
    dataOld?.gender ? `admin/users/input/lists?type=${dataOld?.gender}` : ""
  );

  const [dataInputs, setDataInputs] = useState(null);
  useEffect(() => {
    setDataInputs(inputs?.data?.data);
  }, [inputs?.data?.data]);

  const { data: Jobs, isLoading: JobsLoading } = useFETCH(
    formData?.employment_status_id &&
      `admin/lists/jobs?type=*&parent_item_id=${formData?.employment_status_id}`
  );
  const dataCitiesAll = city?.data?.data?.items?.map((e) => {
    return {
      id: e?.id,
      name: e.name?.ar,
    };
  });
  const dataJobsAll = Jobs?.data?.data?.items?.map((e) => {
    return {
      id: e?.id,
      name: e.name?.ar,
    };
  });

  const dataUsersLists = [
    {
      name: "country_id",
      text: content.Countries,
      value: formData?.country_id,
      options: dataInputs?.countries,
    },
    {
      name: "city_id",
      text: content.City,
      value: formData?.city_id,
      options: dataCitiesAll,
    },
    {
      name: "nationality_id",
      text: content.Nationalities,
      value: formData?.nationality_id,
      options: dataInputs?.nationalities,
    },
    {
      name: "employment_status_id",
      text: content.employmentStatuses,
      value: formData?.employment_status_id,
      options: dataInputs?.employment_statuses,
    },
    {
      name: "job_id",
      text: content.Job,
      value: formData?.job_id,
      options: dataJobsAll,
    },
    {
      name: "social_status_id",
      text: content.SocialName,
      value: formData?.social_status_id,
      options: dataInputs?.socials_statuses,
    },
    {
      name: "religiosity_status_id",
      text: content.Commitment,
      value: formData?.religiosity_status_id,
      options: dataInputs?.religiosity_statuses,
    },
    {
      name: "prayer_status_id",
      text: content.prayerStatuses,
      value: formData?.prayer_status_id,
      options: dataInputs?.prayer_statuses,
    },
    {
      name: "skin_color_id",
      text: content.SkinColor,
      value: formData?.skin_color_id,
      options: dataInputs?.skin_colors,
    },
    {
      name: "health_status_id",
      text: content.HealthStatus,
      value: formData?.health_status_id,
      options: dataInputs?.healths_statuses,
    },
    {
      name: "educational_level_id",
      text: content.Degrees,
      value: formData?.educational_level_id,
      options: dataInputs?.educational_levels,
    },
    {
      name: "gender",
      text: content.Gender,
      value: formData?.gender,
      options: [
        { name: "ذكر", id: "MALE" },
        { name: "أنثى", id: "FEMALE" },
      ],
    },
    {
      name: "monthly_income_range_id",
      text: content.MonthlyIncome,
      value: formData?.monthly_income_range_id,
      options: dataInputs?.monthly_income_ranges,
    },
    {
      name: "physique_status_id",
      text: content.Physique,
      value: formData?.physique_status_id,
      options: dataInputs?.physiques_statuses,
    },
    {
      name: "financial_status_id",
      text: content.Finance,
      value: formData?.financial_status_id,
      options: dataInputs?.financial_statuses,
    },
    {
      name:
        (formData?.gender === "MALE" && "beard_status_id") ||
        (formData?.gender === "FEMALE" && "hijab_status_id"),
      text:
        (formData?.gender === "MALE" && content.beardStatuses) ||
        (formData?.gender === "FEMALE"
          ? content.hijabStatuses
          : content.beardStatuses),
      value:
        (formData?.gender === "MALE" && formData.beard_status_id) ||
        (formData?.gender === "FEMALE" && formData.hijab_status_id),
      options:
        formData?.gender === "MALE"
          ? dataInputs?.beard_statuses
          : dataInputs?.hijab_statuses,
    },
    {
      name: "smoking_status_id",
      text: content.smokingStatuses,
      value: formData?.smoking_status_id,
      options: dataInputs?.smoking_statuses,
    },
    {
      name: "marriage_status_id",
      text: content.MarriageStatuses,
      value: formData?.marriage_status_id,
      options: dataInputs?.marriage_statuses,
      gender: "MALE",
    },
  ];
  const dataUsersInput = [
    {
      type: "string",
      name: "name",
      value: formData?.name,
      placeholder: content.Name,
    },
    {
      type: "string",
      name: "username",
      value: formData?.username,
      placeholder: content.Username,
    },
    {
      type: "email",
      name: "email",
      value: formData?.email,
      placeholder: content.Email,
    },
    {
      type: "number",
      name: "phone_number",
      value: formData?.phone_number,
      placeholder: content.PhoneNumber,
    },
    // {
    //   type: "number",
    //   name: "age",
    //   value: formData?.age,
    //   placeholder: content.Age,
    // },
    // {
    //   type: "number",
    //   name: "height",
    //   value: formData?.height,
    //   placeholder: content.Length,
    // },
    // {
    //   type: "number",
    //   name: "weight",
    //   value: formData?.weight,
    //   placeholder: content.Weight,
    // },
    // {
    //   type: "number",
    //   name: "children",
    //   value: formData?.children,
    //   placeholder: content.Children,
    // },
  ];

  return (
    <div>
      <Container>
        <Col className="bg-white shadow-sm rounded-2xl p-5">
          <Row className="items-center">
            <TitleEdit />
            {dataUsersInput.map((e, i) => {
              return (
                <Col md={6} key={i}>
                  <Input
                    type={e.type}
                    name={e.name}
                    value={e.value}
                    placeholder={e.placeholder}
                    onChange={handleChangeInput}
                  />
                </Col>
              );
            })}
            <Col md={6}>
              <PasswordInput name="password" onChange={handleChangeInput} />
            </Col>
            <Col md={6}>
              <PasswordInput
                name="passwordConfirmation"
                onChange={handleChangeInput}
                placeholder="تأكيد كلمة السر"
              />
            </Col>
            {dataUsersLists.map((e, i) => {
              return (
                <Col md={6} key={i}>
                  <MultipleSelect
                    className={`${
                      formData?.gender === "MALE" ||
                      (e.name === "marriage_status_id" && "hidden")
                    }`}
                    name={e.name}
                    value={e.value}
                    options={e.options}
                    text={e.text}
                    onChange={handleChangeSelect}
                  />
                </Col>
              );
            })}
            <Col md={5} className="mx-auto">
              <SliderMUI
                title={content.Age}
                unit="years"
                max={70}
                min={18}
                name="age"
                value={formData?.age}
                onChange={handleChangeInput}
              />
            </Col>
            <Col md={5} className="mx-auto">
              <SliderMUI
                title={content.Length}
                unit="cm"
                max={220}
                min={100}
                value={formData?.height}
                onChange={handleChangeInput}
                name="height"
              />
            </Col>
            <Col md={5} className="mx-auto">
              <SliderMUI
                title={content.Weight}
                unit="kg"
                max={200}
                min={18}
                value={formData?.weight}
                onChange={handleChangeInput}
                name="weight"
              />
            </Col>
            <Col md={5} className="mx-auto">
              <SliderMUI
                title={content.Children}
                unit=" "
                max={12}
                name="children"
                value={formData?.children}
                onChange={handleChangeInput}
              />
            </Col>
            <Col md={6}>
              <TextArea
                name="own_props"
                value={formData?.own_props}
                onChange={handleChangeInput}
                title={content.MySpecifications}
              />
            </Col>
            <Col md={6}>
              <TextArea
                name="future_wife_props"
                value={formData?.future_wife_props}
                onChange={handleChangeInput}
                title={content.MyPartnerSpecifications}
              />
            </Col>
            <Col md={9}>
              <AddImage
                type="IMAGES"
                name="images"
                title={content.imageOptional}
                newImages={viewImages}
                setViewImages={setViewImages}
                setFormData={setFormData}
                onChange={handleChangeArrayImages}
                images={userImages}
                setOldImages={setUserImages}
                formData={formData}
                isLoading={isLoading}
                index={counter}
                setIndex={setCounter}
              />
            </Col>
            <Col col={2}>
              <Checkbox
                name="is_active"
                checked={formData.is_active}
                onChange={handleChangeInput}
                title="مفعل"
              />
            </Col>
            {loading ||
            load ||
            (id !== "add" && isLoading) ||
            (id !== "add" && cityLoading) ||
            (id !== "add" && JobsLoading) ? (
              <Loading />
            ) : (
              ""
            )}
            <Back
              name="حفظ"
              onClick={(e) => {
                handleSubmitMain(e);
                setCounter(0);
              }}
            />
          </Row>
        </Col>
      </Container>
    </div>
  );
}
export default AddUsers;
