import { useParams } from "react-router-dom";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Add, PopupTable, Table } from "../../components";
function Blog() {
  const { id } = useParams();
  const { content } = useContextTranslate();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/blog/sections/${id}/posts`,
    `admin/blog/sections/${id}/posts`
  );
  const dataAll = data?.data?.data?.data?.map((e) => {
    return {
      image: fileUrl + e.image?.ar,
      id: e.id,
      title_en: e.title?.ar,
      meta_tags_ar: e.meta_tags?.ar,
      content_ar: <PopupTable text={e.content?.ar} />,
    };
  });
  return (
    <div>
      <Container>
        <Row justify="between">
          <Col className="bg-white shadow-sm rounded-2xl p-5">
            <Add
              titles={content.ListOfBlogs}
              title={content.AddBlog}
              link={`/blog-sections/posts/${id}/add`}
            />
            <Table
              thead={[
                content.Photos,
                content.Id,
                content.Title,
                content.Tags,
                content.content,
              ]}
              pageCount={Math.ceil(
                data?.data.data.total / data?.data.data.per_page
              )}
              tData={dataAll}
              tbody={["image", "id", "title_en", "meta_tags_ar", "content_ar"]}
              funDelete={deleteItem}
              edit={`blog-sections/posts/${id}`}
              isLoading={isLoading}
              isAdmin={true}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Blog;
