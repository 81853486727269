import { useParams } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Add, PopupTable, Table } from "../../components";
function Support({ type, rout }) {
  const { id } = useParams();
  const { content } = useContextTranslate();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/faqs-sections/${id}/faqs?type=${type}`,
    `admin/faqs-sections/${id}/faqs`
  );
  const dataAll = data?.data.data.data?.map((e) => {
    return {
      id: e.id,
      title_ar: e.title?.ar,
      content_ar: <PopupTable text={e.content?.ar} />,
    };
  });
  return (
    <div>
      <Container>
        <Row justify="between">
          <Col
            lg={8}
            md={10}
            className="bg-white shadow-sm rounded-2xl p-5 mx-auto">
            <Add
              titles={content.Questions}
              title={content.Question}
              link={`/${rout}/questions/${id}/add`}
            />
            <Table
              thead={[content.Id, content.Question, content?.Response]}
              pageCount={Math.ceil(
                data?.data.data?.total / data?.data.data?.per_page
              )}
              tData={dataAll}
              tbody={["id", "title_ar", "content_ar"]}
              funDelete={deleteItem}
              edit={`${rout}/questions/${id}`}
              isLoading={isLoading}
              isAdmin={true}
              show="questions"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Support;
