import React, { useEffect, useState } from "react";
import { Title, HTMLEditor } from "../../components";
import { Col, Row } from "../../Tools/Grid-system";
import { useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import Loading from "../../Tools/Loading";

const GeneralInfo = () => {
  const { data, isLoading } = useFETCH("admin/info/all");
  const socialMedialinks = data?.data.data;
  const [section, setSection] = useState({
    social: false,
    privacy_policy: false,
    about_us: false,
    free_messages: false,
  });

  const { handleChangeInput, handleSubmit, setFormData } = usePOST({});
  useEffect(() => {
    setFormData(socialMedialinks);
  }, [socialMedialinks]);
  const { content } = useContextTranslate();

  return (
    <div className="px-4">
      <Title title={content.generalInfo} />
      <Col className=" px-5">
        <Row justify="center">
          <div className="bg-white border-2 border-Main rounded-2xl py-10 md:px-16 flex gap-5 flex-wrap">
            {isLoading && <Loading />}
            <button
              className="w-[50%] mx-auto text-xl max-sm:text-lg max-sm:w-[80%] px-5 py-2 text-white bg-Main font-semibold rounded-full"
              onClick={() =>
                setSection({ ...section, social: !section.social })
              }
            >
              مواقع التواصل الاجتماعي
            </button>
            {section.social && (
              <div className="flex justify-evenly gap-5 flex-wrap">
                <Col className="w-[40%]">
                  <label className="font-bold">{content.twitterLink}</label>
                  <input
                    name="twitter"
                    type="text"
                    className="border-2 border-Main p-4 rounded-2xl my-3 w-full ltr"
                    placeholder="Enter Twitter Link"
                    defaultValue={
                      isLoading || !socialMedialinks
                        ? ""
                        : socialMedialinks["twitter"]
                    }
                    onChange={handleChangeInput}
                  />
                </Col>
                <Col className="w-[40%]">
                  <label className="font-bold">{content.facebookLink}</label>
                  <input
                    name="facebook"
                    type={"text"}
                    className="border-2 border-Main p-4 rounded-2xl my-3 w-full ltr"
                    placeholder="Enter FaceBook Link"
                    defaultValue={
                      isLoading || !socialMedialinks
                        ? ""
                        : socialMedialinks["facebook"]
                    }
                    onChange={handleChangeInput}
                  />
                </Col>
                <Col className="w-[40%]">
                  <label className="font-bold">{content.instagramLink}</label>

                  <input
                    name="instagram"
                    type={"text"}
                    className="border-2 border-Main p-4 rounded-2xl my-3 w-full ltr"
                    placeholder="Enter Instagram Link"
                    defaultValue={
                      isLoading || !socialMedialinks
                        ? ""
                        : socialMedialinks["instagram"]
                    }
                    onChange={handleChangeInput}
                  />
                </Col>
                <Col className="w-[40%]">
                  <label className="font-bold">{content.youtubeLink}</label>

                  <input
                    name="youtube"
                    type={"text"}
                    className="border-2 border-Main p-4 rounded-2xl my-3 w-full ltr"
                    placeholder="Enter Youtube Link"
                    defaultValue={
                      isLoading || !socialMedialinks
                        ? ""
                        : socialMedialinks["youtube"]
                    }
                    onChange={handleChangeInput}
                  />
                </Col>
                <Col className="w-[40%]">
                  <label className="font-bold">{content.googlePlayLink}</label>

                  <input
                    name="google_play_url"
                    type={"text"}
                    className="border-2 border-Main p-4 rounded-2xl my-3 w-full ltr"
                    placeholder="Enter Google App Link"
                    defaultValue={
                      isLoading || !socialMedialinks
                        ? ""
                        : socialMedialinks["google_play_url"]
                    }
                    onChange={handleChangeInput}
                  />
                </Col>
                <Col className="w-[40%]">
                  <label className="font-bold">{content.appStoreLink}</label>

                  <input
                    defaultValue={
                      isLoading || !socialMedialinks
                        ? ""
                        : socialMedialinks["app_store_url"]
                    }
                    name="app_store_url"
                    type={"text"}
                    className="border-2 border-Main p-4 rounded-2xl my-3 w-full ltr"
                    placeholder="Enter AppStore App Link"
                    onChange={handleChangeInput}
                  />
                </Col>
              </div>
            )}
            <button
              className="w-[50%] mx-auto text-xl max-sm:text-lg max-sm:w-[80%] px-5 py-2 text-white bg-Main font-semibold rounded-full"
              onClick={() =>
                setSection({
                  ...section,
                  privacy_policy: !section.privacy_policy,
                })
              }
            >
              سياسة الخصوصية
            </button>
            {section.privacy_policy && (
              <div className="flex justify-evenly gap-5 flex-wrap">
                <Col>
                  <HTMLEditor
                    title={`${content.PrivacyPolicy} ${content.Arabic}`}
                    value={
                      isLoading ? "" : socialMedialinks["privacy_policy_ar"]
                    }
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, privacy_policy_ar: e }))
                    }
                  />
                </Col>
                <Col>
                  <HTMLEditor
                    title={`${content.PrivacyPolicy} ${content.English}`}
                    value={
                      isLoading ? "" : socialMedialinks["privacy_policy_en"]
                    }
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, privacy_policy_en: e }))
                    }
                  />
                </Col>
              </div>
            )}
            <div className="w-full">
              <div className="flex justify-center items-center">
                <button
                  className="w-[50%] mx-auto text-xl max-sm:text-lg max-sm:w-[80%] px-5 py-2 text-white bg-Main font-semibold rounded-full"
                  onClick={() =>
                    setSection({
                      ...section,
                      free_messages: !section.free_messages,
                    })
                  }
                >
                  عدد الرسائل المجانية
                </button>
              </div>
              {section.free_messages && (
                <div className="">
                  <Col className="mx-auto">
                    <label className="font-bold">عدد الرسائل المجانية</label>
                    <input
                      name="free_messages_count"
                      type="number"
                      className="border-2 border-Main p-4 rounded-2xl my-3 sm:w-1/2"
                      placeholder="عدد الرسائل المجانية"
                      onChange={handleChangeInput}
                      defaultValue={socialMedialinks.free_messages_count}
                    />
                  </Col>
                </div>
              )}
            </div>
            <button
              className="w-[50%] mx-auto text-xl max-sm:text-lg max-sm:w-[80%] px-5 py-2 text-white bg-Main font-semibold rounded-full"
              onClick={() =>
                setSection({ ...section, about_us: !section.about_us })
              }
            >
              معلومات عنا
            </button>
            {section.about_us && (
              <div className="flex justify-evenly gap-5 flex-wrap">
                <Col>
                  <HTMLEditor
                    title={`${content.AboutUs} ${content.Arabic}`}
                    value={isLoading ? "" : socialMedialinks["about_us_ar"]}
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, about_us_ar: e }))
                    }
                  />
                </Col>
                <Col>
                  <HTMLEditor
                    title={`${content.AboutUs} ${content.English}`}
                    value={isLoading ? "" : socialMedialinks["about_us_en"]}
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, about_us_en: e }))
                    }
                  />
                </Col>
              </div>
            )}

            <div
              onClick={() =>
                handleSubmit("admin/info/update/all", false, false, true)
              }
              className="cursor-pointer w-full border border-Main py-3 mt-10 rounded-2xl text-white font-semibold text-xl text-center bg-gradient-to-l to-Main from-Third"
            >
              {content.SaveChanges}
            </div>
          </div>
        </Row>
      </Col>
    </div>
  );
};

export default GeneralInfo;
