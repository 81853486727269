import Input from "../../components/Input/Input";
import { useEffect, useState } from "react";
import { MainButton } from "../../components";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { usePOST } from "../../Tools/APIs/useMyAPI";
import bg from "../../images/Group 1000005479.png";
function SetPassword() {
  const [password, setPassword] = useState(false);
  const { setFormData, handleSubmit, loading, handleChangeInput, formData } =
    usePOST({});
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit("admin/reset-password", "/login");
  };

  useEffect(() => {
    setFormData({
      ...formData,
      verification_code: sessionStorage.getItem("code"),
      email: sessionStorage.getItem("email"),
    });
  }, []);
  return (
    <div className="fixed w-full h-full bg-[#FFF4F4] top-0 left-0 z-50 flex items-center justify-center">
      <div className="w-2/3 max-lg:w-[90%]">
        <Row className="h-[70vh] flex justify-center items-center">
          <Col
            md={8}
            className="mx-auto space-y-3 bg-white p-8 rounded-3xl border-t shadow-xl"
          >
            <h1 className="text-2xl font-semibold text-center">كلمة السر</h1>
            <Input
              name="password"
              type={!password ? "password" : "text"}
              title="أدخل كلمة المرور"
              onChange={handleChangeInput}
            />
            <Input
              name="password_confirmation"
              type={!password ? "password" : "text"}
              title="تأكيد كلمة المرور"
              onChange={handleChangeInput}
            />
            <div className="text-lg mt-5 flex items-center">
              <input
                type="checkbox"
                onClick={() => setPassword(!password)}
                name="checkbox"
                className="mx-3 w-fit"
              />
              <span>إظهار كلمة المرور</span>
            </div>
            {loading ? <Loading /> : ""}

            <MainButton
              className="w-[150px] mx-auto mt-2"
              name="تأكيد كلمة المرور"
              onClick={handleSubmitMain}
            />
          </Col>
        </Row>
      </div>
      <img src={bg} alt="" className="absolute bottom-0 w-full -z-10" />
    </div>
  );
}
export default SetPassword;
