import { useState } from "react";
import { usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import { Title } from "../../components";
import Loading from "../../Tools/Loading";
import { useContextTranslate } from "../../Translate/ContextTranslate";

const ChangePassword = () => {
  const [biShow, setBiShow] = useState(false);
  const { handleSubmit, loading, handleChangeInput } = usePOST({});
  const handleSubmitBlue = () => {
    handleSubmit("admin/change-password", true);
  };
  const { content } = useContextTranslate();
  return (
    <div>
      <Title title={content.ChangePassword} />
      <Col md={7} className="mx-auto px-5">
        <Row justify="center">
          <div className="bg-white border-2 border-Main rounded-2xl py-10 md:px-16">
            <Col>
              <input
                name="old_password"
                type={biShow ? "text" : "password"}
                className="border-2 border-Main py-4 rounded-2xl my-3 w-full"
                placeholder={content.OldPassword}
                onChange={handleChangeInput}
              />
            </Col>
            <Col>
              <input
                name="password"
                type={biShow ? "text" : "password"}
                className="border-2 border-Main py-4 rounded-2xl my-3 w-full"
                placeholder={content.NewPassword}
                onChange={handleChangeInput}
              />
            </Col>
            <Col>
              <input
                name="password_confirmation"
                type={biShow ? "text" : "password"}
                className="border-2 border-Main py-4 rounded-2xl my-3 w-full"
                placeholder={content.RewritePassword}
                onChange={handleChangeInput}
              />
            </Col>
            <div className="text-end text-l flex items-center justify-start gap-2">
              <input
                type="checkbox"
                className="relative w-5 h-5"
                onChange={(e) => setBiShow(e.target.checked)}
              />
              <span>{content.ShowPassword}</span>
              {loading ? <Loading /> : ""}
            </div>
            <div
              onClick={handleSubmitBlue}
              className="cursor-pointer w-full border border-Main py-3 mt-10 rounded-2xl text-white font-semibold text-xl text-center bg-gradient-to-l to-Main from-Third"
            >
              {content.SaveChanges}
            </div>
          </div>
        </Row>
      </Col>
    </div>
  );
};

export default ChangePassword;
