import { useEffect } from "react";
import image from "../../Svg/exclamation-mark_3588294.svg";
import { useContextHook } from "../../Context/ContextOPen";

function Message() {
  const { message, setMessage } = useContextHook();

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage("");
      }, 10000000);
    } else {
      setMessage("");
    }
  }, [message]);
  return (
    <>
      {
        <div
          className={`fixed transition-all left-7 bg-whit border-t-4 border-red-600 shadow-lg z-[999999999999] rounded-b-xl overflow-hidden ${
            message?.length !== 0 ? "bottom-10" : "-bottom-full"
          }`}
          onClick={() => setMessage([])}
        >
          <div className="bg-red-500 text-white font-semibold py-5 px-7">
            <img
              src={image}
              className="w-8 mx-auto mb-2 h-8 rounded-full"
              alt=""
            />
            {message.map((error, index) => (
              
                <div key={index} className="flex items-center">
                  <span className="w-2 h-2 rounded-full bg-white mx-2"></span>
                  <p className="mb-2">{error}</p>
                </div>
              )
            )}
          </div>
        </div>
      }
    </>
  );
}

export default Message;
