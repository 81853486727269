import { useParams } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Add, Table } from "../../components";
function Nationalities({ title, name, type, rout }) {
  const { id } = useParams();
  const { content } = useContextTranslate();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/lists/${type}?type=*&parent_item_id=${id}`,
    `admin/lists/${type}/items`
  );
  const dataAll = data?.data?.data?.items?.map((e) => {
    return {
      id: e?.id,
      name_ar: e.name?.ar,
      name_en: e.name?.en,
    };
  });
  return (
    <div>
      <Container>
        <Row>
          <Col lg={10} className="bg-white shadow-sm rounded-2xl p-5 mx-auto">
            <Add
              titles={title}
              title={name}
              link={`/${rout}/${type}/${id}/add`}
            />
            <Table
              thead={[content.Id, content.NameAr, content.NameEn]}
              pageCount={Math.ceil(
                data?.data.data?.total / data?.data.data?.per_page
              )}
              tData={dataAll}
              tbody={["id", "name_ar", "name_en"]}
              funDelete={deleteItem}
              edit={`${rout}/${type}/${id}`}
              hideName="true"
              isLoading={isLoading}
              isAdmin={true}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Nationalities;
