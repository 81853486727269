import { useEffect } from "react";
import { usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Input, MainButton } from "../../components";
import bg from "../../images/Group 1000005479.png";
import Logo from "../../images/logo.png";
function ForgetPassword() {
  const { handleSubmit, loading, handleChangeInput, formData, error } = usePOST(
    {}
  );
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit("admin/send/verification-code", "/verification-code");
  };
  useEffect(() => {
    sessionStorage.setItem("email", formData?.email);
  }, [formData]);
  const { content } = useContextTranslate();
  return (
    <div className="fixed w-full h-full bg-[#FFF4F4] top-0 left-0 z-50 flex items-center justify-center">
      <div className="w-2/3 max-lg:w-[90%]">
        <img src={Logo} alt="" className="w-[300px] mx-auto object-contain" />
        <h1 className="text-xl font-semibold text-center my-2">
          {content.forgetPassword}
        </h1>
        <Row>
          <Col md={8} className="mx-auto">
            <Input
              name="email"
              onChange={handleChangeInput}
              placeholder={content.EnterYourEmail}
              className="bg-white w-full border-[3px] my-1 !py-3 rounded-xl"
              error={error?.email}
            />
          </Col>
        </Row>
        {loading ? <Loading /> : ""}
        <MainButton
          name={content.Next}
          onClick={handleSubmitMain}
          className="!px-10 py-3 !w-fit !mx-auto"
        />
      </div>
      <img src={bg} alt="" className="absolute bottom-0 w-full -z-10" />
    </div>
  );
}
export default ForgetPassword;
