import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Add, MainTitle, PopupTable, Table } from "../../components";
function Advertisement() {
  const { content } = useContextTranslate();
  const { data, isLoading, deleteItem } = useFETCH(`admin/ads`, `admin/ads`);

  const dataAll = data?.data?.data?.data?.map((e) => {
    return {
      image: e.type === "IMAGE" ? fileUrl + e.file?.ar : "video",
      id: e?.id,
      text_ar: <PopupTable text={`${e.text?.ar}`} />,
      from_age: e.from_age,
      to_age: e.to_age,
      views_count: e.views_count,
      clicks_count: e.clicks_count,
    };
  });

  return (
    <div>
      <MainTitle title={content.Advertisement} />
      <Container>
        <Row justify="between">
          <Col className="bg-white shadow-sm rounded-2xl p-5 mx-auto">
            <Add
              link="/advertisement/add"
              titles={content.Advertisement}
              title={content.Adds}
            />
            <Table
              deleteAll={true}
              thead={[
                content.Photos,
                content.Id,
                content.NameAr,
                content.FromAge,
                content.ToAge,
                content.ViewsCount,
                content.Clicks,
              ]}
              pageCount={Math.ceil(
                data?.data.data.total / data?.data.data.per_page
              )}
              tData={dataAll}
              tbody={[
                "",
                "image",
                "id",
                "text_ar",
                "from_age",
                "to_age",
                "views_count",
                "clicks_count",
              ]}
              funDelete={deleteItem}
              edit="advertisement"
              showCountry={`advertisement/countries`}
              isLoading={isLoading}
              show="advertisement/views"
              clicks="advertisement/clicks"
              position="advertisement/position"
              showContent="عرض المشاهدات على هذا الإعلان"
              isAdmin={true}
              isAds={true}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Advertisement;
