import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Back, HTMLEditor, Input } from "../../components";
function AddSupport({ type }) {
  const { content } = useContextTranslate();
  const { id, idd } = useParams();
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    formData,
    loading,
  } = usePOST({ type: `${type}` });
  const { data, isLoading } = useFETCH(
    idd === "add" ? "" : `admin/faqs-sections/${id}/faqs/${idd}?type=${type}`
  );
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      idd === "add"
        ? `admin/faqs-sections/${id}/faqs?type=${type}`
        : `admin/faqs-sections/${id}/faqs/${idd}?type=${type}`
    );
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    idd !== "add" &&
      setFormData({
        ...formData,
        _method: "PUT",
        title_ar: dataOld?.title?.ar,
        title_en: dataOld?.title?.en,
        content_ar: dataOld?.content?.ar,
        content_en: dataOld?.content?.en,
      });
  }, [dataOld]);
  return (
    <div>
      <Container>
        <Row className="bg-white shadow-sm rounded-2xl p-5">
          <p className="font-bold text-xl mx-3 mb-5">
            {content.BasicInformation}
          </p>
          <Col md={6}>
            <Input
              name="title_ar"
              onChange={handleChangeInput}
              placeholder={content?.Question + " " + content.Arabic}
              value={formData?.title_ar}
            />
          </Col>
          <Col md={6}>
            <Input
              name="title_en"
              onChange={handleChangeInput}
              placeholder={content?.Question + " " + content.English}
              value={formData?.title_en}
            />
          </Col>
          <Col>
            <HTMLEditor
              title={content?.Response + " " + content.Arabic}
              value={formData?.content_ar}
              onChange={(e) => setFormData({ ...formData, content_ar: e })}
            />
          </Col>
          <Col>
            <HTMLEditor
              title={content?.Response + " " + content.English}
              value={formData?.content_en}
              onChange={(e) => setFormData({ ...formData, content_en: e })}
            />
          </Col>
          {loading || (idd !== "add" && isLoading) ? <Loading /> : ""}
          <Back name="حفظ" onClick={handleSubmitMain} />
        </Row>
      </Container>
    </div>
  );
}
export default AddSupport;
