import { BiShow } from "react-icons/bi";
import { Link } from "react-router-dom";
import IconHover from "../IconHover/IconHover";
import Countries from "../../Svg/Vector.svg";
import { GiClick } from "react-icons/gi";
import { TbViewfinder } from "react-icons/tb";
import { MdOutlineReport } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
function Show({
  show,
  text,
  countries,
  id,
  click,
  position,
  report,
  isPackage,
}) {
  return (
    <>
      <IconHover id={id || "show"} text={text || "عرض"}>
        <div
          className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
        >
          <Link to={show || ""}>
            {countries ? (
              <img
                src={Countries}
                alt="countries"
                className="w-[20px] h-[20px]"
              />
            ) : report ? (
              <MdOutlineReport size={25} className="text-yellow-400" />
            ) : click ? (
              <GiClick size={25} className="text-Main" />
            ) : position ? (
              <TbViewfinder size={25} className="text-gray-700" />
            ) : isPackage ? (
              <FaUserAlt size={20} className="text-Third" />
            ) : (
              <BiShow size={25} className="cursor-pointer text-blue-600" />
            )}
          </Link>
        </div>
      </IconHover>
    </>
  );
}
export default Show;
