function Statistics({ img, name, number }) {
  return (
    <div>
      <div className="bg-white rounded-xl py-4 px-2 max-sm:py-2 flex items-center gap-2 text-[14px] max-sm:text-[10px]">
        <img src={img} alt="" className="w-12 h-12 max-sm:h-8 max-sm:w-8" />
        <div>
          <p>{name}</p>
          <p className="text-gray-500">{number}</p>
        </div>
      </div>
    </div>
  );
}
export default Statistics;
