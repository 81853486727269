import { useState } from "react";
import { Col, Row } from "../../Tools/Grid-system";
import MainButton from "../Buttons/MainButton";
import IconHover from "../IconHover/IconHover";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import Input from "../Input/Input";
const Popup = ({
  description,
  onClick,
  yes,
  cancel,
  children,
  id,
  text,
  desClass,
  hover,
  hide,
  className,
  input,
  name,
  onChange,
  btnClassName,
}) => {
  const [sure, setSure] = useState(false);

  const { content } = useContextTranslate();
  return (
    <div>
      {sure && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 z-50"
            onClick={() => setSure(false)}></div>
          <div
            className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg/black/60 z-50 flex justify-center items-center max-sm:w-full`}>
            <div
              className={`bg-white !z-[999] rounded-3xl w-fit h-fit py-7 max-h-[90vh] overflow-y-auto sidebar max-sm:mx-6 ${className}`}>
              {description && (
                <p
                  className={`font-semibold text-2xl text-black text-center px-4 mb-9 ${desClass}`}>
                  {description}
                </p>
              )}
              {input && (
                <Input
                  className="w-[340px]"
                  placeholder="أدخل الرد"
                  name={name}
                  onChange={onChange}
                />
              )}
              {hide ? (
                ""
              ) : (
                <Row justify="center">
                  <Col xs={3} sm={4}>
                    <MainButton
                      onClick={() => {
                        setSure(false);
                        onClick();
                      }}
                      name={yes || content.yes}
                      className="!bg-Main hover:!bg-Main/60 transition-all"
                    />
                  </Col>
                  <Col xs={3} sm={4}>
                    <MainButton
                      onClick={() => setSure(false)}
                      name={cancel || content.cancel}
                      className="!bg-Secondary hover:!bg-Secondary/60 transition-all"
                    />
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </>
      )}

      <div
        onClick={() => {
          setSure(true);
          // setOpenMenu(false);
        }}>
        <IconHover id={id} text={text}>
          <div
            className={`${
              hover
                ? ""
                : `${
                    btnClassName ? btnClassName : "w-10 h-10 rounded-full"
                  } hover:bg-slate-200 flex cursor-pointer justify-center items-center
                  `
            }`}>
            {children}
          </div>
        </IconHover>
      </div>
    </div>
  );
};

export default Popup;
