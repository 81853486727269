import { Link } from "react-router-dom";
import { useLOGIN } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Input, MainButton, PasswordInput } from "../../components";
import bg from "../../images/Group 1000005479.png";
import Logo from "../../images/logo.png";

const Login = () => {
  const { handleSubmit, loading, handleChange, error } = useLOGIN();
  const handleSubmitMain = (e) => {
    if (e) {
      e.preventDefault();
      handleSubmit("admin/login");
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmitMain();
    }
  };
  const { content } = useContextTranslate();
  return (
    <div className="fixed w-full h-full bg-[#FFF4F4] top-0 left-0 z-50 flex items-center justify-center">
      <div className="w-2/3 max-lg:w-[90%]">
        <img src={Logo} alt="" className="mx-auto object-cover" />
        <p className="text-xl font-semibold text-center my-2">
          {content.LoginToAccess}
        </p>
        <Row>
          <Col md={8} className="mx-auto">
            <Input
              name="username_or_email"
              onChange={handleChange}
              placeholder={content.username}
              className="bg-white w-full border-[3px] my-1 !py-3 rounded-xl"
              error={error?.errors?.username_or_email}
              onKeyDown={handleKeyDown}
            />
          </Col>
          <Col md={8} className="mx-auto">
            <PasswordInput
              name="password"
              onChange={handleChange}
              className="bg-white w-full border-[3px] my-1 !py-3 rounded-xl"
              top="top-4"
              error={error?.errors?.password}
              onKeyDown={handleKeyDown}
            />
            <div className="text-red-500 font-semibold text-center">
              {error?.error}
            </div>
            <Link
              to="/forget-password"
              className="underline text-whit text-start cursor-pointer"
            >
              {content.ForgetPassword}
            </Link>
          </Col>
        </Row>
        {loading ? <Loading /> : ""}
        <div className="flex justify-center items-center">
          <MainButton
            name={content.Send}
            className="!px-10 py-3 !w-fit !mx-auto"
            onClick={handleSubmitMain}
          />
        </div>
      </div>
      <img src={bg} alt="" className="absolute bottom-0 w-full -z-10" />
    </div>
  );
};

export default Login;
