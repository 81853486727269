import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { clickZoomInImage, fileUrl, useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Back, Input, TitleEdit } from "../../components";
import AddImage from "../../Tools/AddFile";
function AddSupportSections({ type }) {
  const { content } = useContextTranslate();
  const { id } = useParams();
  const {
    handleChangeArrayImages,
    handleChangeInput,
    handleSubmit,
    setFormData,
    setViewFile,
    setViewImages,
    setImages,
    viewImages,
    viewFile,
    formData,
    loading,
    images,
  } = usePOST({});


  const { data, isLoading } = useFETCH(
    id === "add" ? "" : `admin/faqs-sections/${id}?type=${type}`
  );
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      id === "add"
        ? `admin/faqs-sections?type=${type}`
        : `admin/faqs-sections/${id}?type=${type}`
    );
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        ...formData,
        _method: "PUT",
        name_ar: dataOld?.name?.ar,
        name_en: dataOld?.name?.en,
      });
    if (id !== "add")
      setViewImages({
        ...viewImages,
        image_en: fileUrl + dataOld?.image?.en,
        image_ar: fileUrl + dataOld?.image?.ar,
      });
  }, [dataOld]);
  return (
    <div>
      <Container>
        <Col md={10} className="mx-auto mt-8">
          <Row className="bg-white shadow-sm rounded-2xl p-5">
            <TitleEdit />
            <Col md={6}>
              <Input
                name="name_ar"
                onChange={handleChangeInput}
                placeholder={content?.Name + " " + content.Arabic}
                value={formData?.name_ar}
              />
            </Col>
            <Col md={6}>
              <Input
                name="name_en"
                onChange={handleChangeInput}
                placeholder={content?.Name + " " + content.English}
                value={formData?.name_en}
              />
            </Col>
            <Col md={6} className="">
              <AddImage
                id="images"
                name="image_ar"
                newImage={viewImages?.image_ar?.[0]}
                oldImage={dataOld?.image && fileUrl + dataOld?.image?.ar}
                onChange={(e) => {
                  setViewImages({
                    ...viewImages,
                    image_ar: [URL.createObjectURL(e.target.files[0])],
                  });

                  setImages({
                    ...images,
                    image_ar: e.target.files[0],
                  });
                }}
                title="إضف صورة بالعربي  "
                clickDeleteImage={() => {
                  setViewImages({
                    ...viewImages,
                    image_ar: "",
                  });
                  setImages({
                    ...images,
                    image_ar: "",
                  });
                }}
              />
            </Col>
            <Col md={6} className="">
              <AddImage
                title={content.addImage + " " + content.English}
                name="image_en"
                id="image_en"
                accept="image_en"
                newImage={viewFile}
                oldImage={dataOld?.image && fileUrl + dataOld?.image?.en}
                onChange={(e) => {
                  setViewImages({
                    ...viewImages,
                    image_en: [URL.createObjectURL(e.target.files[0])],
                  });
                  setImages({
                    ...images,
                    image_en: e.target.files[0],
                  });
                }}
                clickDeleteImage={() => {
                  setViewFile("");
                  setFormData({ ...formData, images: "" });
                }}
              />
            </Col>
            {viewImages?.image_ar || "" ? (
              <Col col={6} className="max-auto">
                <div className="relative">
                  <span
                    onClick={() => {
                      setViewImages({
                        ...viewImages,
                        image_ar: "",
                      });
                      setImages({
                        ...images,
                        image_ar: "",
                      });
                    }}
                    className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute"
                  >
                    X
                  </span>

                  <img
                    src={viewImages?.image_ar}
                    alt=""
                    className="w-[120px] h-[120px] cursor-pointer rounded-2xl"
                    onClick={clickZoomInImage}
                  />

                </div>
              </Col>
            ) : (
              ""
            )}
            {viewImages?.image_en || "" ? (
              <Col col={6} className="max-auto">
                <div className="relative">
                  <span
                    onClick={() => {
                      setViewImages({
                        ...viewImages,
                        image_en: "",
                      });
                      setImages({
                        ...images,
                        image_en: "",
                      });
                    }}
                    className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute"
                  >
                    X
                  </span>
                  <img
                    src={viewImages?.image_en}
                    alt=""
                    className="w-[120px] h-[120px] cursor-pointer rounded-2xl"
                    onClick={clickZoomInImage}
                  />

                </div>
              </Col>
            ) : (
              ""
            )}
            {loading || (id !== "add" && isLoading) ? <Loading /> : ""}
            <Back name="حفظ" onClick={handleSubmitMain} />
          </Row>
        </Col>
      </Container>
    </div>
  );
}
export default AddSupportSections;
