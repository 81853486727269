function MainTitle({ title }) {
  return (
    <div>
      <li
        className={`text-lg max-sm:text-[15px] md:mx-5 font-semibold text-Third pb-2 w-fit my-5`}
      >
        {title}
      </li>
    </div>
  );
}
export default MainTitle;
