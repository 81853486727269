import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  clickZoomInImage,
  fileUrl,
  useFETCH,
  usePOST,
} from "../../Tools/APIs/useMyAPI";
import AddImage from "../../Tools/AddFile";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import {
  Back,
  HTMLEditor,
  Input,
  MultipleSelect,
  TitleEdit,
} from "../../components";
function AddAdvertisement() {
  const { content } = useContextTranslate();
  const { id } = useParams();
  const {
    handleChangeSelect,
    handleChangeInput,
    handleSubmit,
    setFormData,
    setViewImages,
    setImages,
    viewImages,
    images,
    formData,
    loading,
  } = usePOST({});
  const { data, isLoading } = useFETCH(id === "add" ? "" : `admin/ads/${id}`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id === "add" ? `admin/ads` : `admin/ads/${id}`);
  };
  let dataOld = data?.data.data;
  console.log(dataOld);
  useEffect(() => {
    id !== "add" &&
      setFormData({
        ...formData,
        _method: "PUT",
        text_ar: dataOld?.text?.ar,
        text_en: dataOld?.text?.en,
        link: dataOld?.link,
        type: dataOld?.type,
        from_age: dataOld?.from_age,
        to_age: dataOld?.to_age,
      });
  }, [dataOld]);
  return (
    <div>
      <Container>
        <Col className="mx-auto mt-8">
          <Row className="bg-white shadow-sm rounded-2xl p-5">
            <TitleEdit />
            <Col md={6}>
              <Input
                type="number"
                name="from_age"
                onChange={handleChangeInput}
                placeholder={content?.FromAge}
                value={formData?.from_age}
              />
            </Col>
            <Col md={6}>
              <Input
                type="number"
                name="to_age"
                onChange={handleChangeInput}
                placeholder={content?.ToAge}
                value={formData?.to_age}
              />
            </Col>
            <Col md={6}>
              <Input
                name="link"
                onChange={handleChangeInput}
                placeholder={content?.Link}
                value={formData?.link}
              />
            </Col>
            <Col md={6}>
              <MultipleSelect
                name="type"
                text={content.Type}
                value={formData?.type}
                options={[
                  { name: "صورة", id: "IMAGE" },
                  { name: "فيديو", id: "VIDEO" },
                ]}
                onChange={handleChangeSelect}
              />
            </Col>
            <Col md={6}>
              <AddImage
                id="image"
                name="file_ar"
                accept="video/*,image/*"
                title={content.File + " " + content.Arabic}
                onChange={(e) => {
                  setViewImages({
                    ...viewImages,
                    file_ar: [URL.createObjectURL(e.target.files[0])],
                  });
                  setImages({
                    ...images,
                    file_ar: e.target.files[0],
                  });
                }}
              />
              {viewImages?.file_ar || "" ? (
                <Col col={6} className="max-auto">
                  <div className="relative">
                    <span
                      onClick={() => {
                        setViewImages({
                          ...viewImages,
                          file_ar: "",
                        });
                        setImages({
                          ...images,
                          file_ar: "",
                        });
                      }}
                      className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute">
                      X
                    </span>
                    {images?.file_ar?.[0]?.type.substring(0, 5) === "video" ? (
                      <video controls className="rounded-2xl">
                        <source src={viewImages?.file_ar?.[0]} />
                      </video>
                    ) : (
                      <img
                        src={viewImages?.file_ar?.[0]}
                        alt=""
                        className="w-[120px] h-[120px] cursor-pointer rounded-2xl"
                        onClick={clickZoomInImage}
                      />
                    )}
                  </div>
                </Col>
              ) : (
                ""
              )}
              {id !== "add" && formData?.type === "IMAGE" ? (
                <img
                  src={fileUrl + dataOld?.file?.ar}
                  alt=""
                  className="w-[120px] h-[120px] cursor-pointer rounded-2xl"
                  onClick={clickZoomInImage}
                />
              ) : (
                id !== "add" &&
                formData?.type === "VIDEO" && (
                  <video controls className="rounded-2xl">
                    <source src={fileUrl + dataOld?.file?.ar} />
                  </video>
                )
              )}
            </Col>
            <Col md={6}>
              <AddImage
                id="imagess"
                name="file_en"
                accept="video/*,image/*"
                title={content.File + " " + content.English}
                onChange={(e) => {
                  setViewImages({
                    ...viewImages,
                    file_en: [URL.createObjectURL(e.target.files[0])],
                  });
                  setImages({
                    ...images,
                    file_en: e.target.files[0],
                  });
                }}
              />
              {viewImages?.file_en || "" ? (
                <Col col={6} className="max-auto">
                  <div className="relative">
                    <span
                      onClick={() => {
                        setViewImages({
                          ...viewImages,
                          file_en: "",
                        });
                        setImages({
                          ...images,
                          file_en: "",
                        });
                      }}
                      className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute">
                      X
                    </span>
                    {images?.file_en?.[0]?.type.substring(0, 5) === "video" ? (
                      <video controls className="rounded-2xl">
                        <source src={viewImages?.file_en?.[0]} />
                      </video>
                    ) : (
                      <img
                        src={viewImages?.file_en?.[0]}
                        alt=""
                        className="w-[120px] h-[120px] cursor-pointer rounded-2xl"
                        onClick={clickZoomInImage}
                      />
                    )}
                  </div>
                </Col>
              ) : (
                ""
              )}
              {id !== "add" && formData?.type === "IMAGE" ? (
                <img
                  src={fileUrl + dataOld?.file?.en}
                  alt=""
                  className="w-[120px] h-[120px] cursor-pointer rounded-2xl"
                  onClick={clickZoomInImage}
                />
              ) : (
                id !== "add" &&
                formData?.type === "VIDEO" && (
                  <video controls className="rounded-2xl">
                    <source src={fileUrl + dataOld?.file?.en} />
                  </video>
                )
              )}
            </Col>
            <Col>
              <HTMLEditor
                title={content?.Description + " " + content.Arabic}
                value={formData?.text_ar}
                onChange={(e) => setFormData({ ...formData, text_ar: e })}
              />
            </Col>
            <Col>
              <HTMLEditor
                title={content?.Description + " " + content.English}
                value={formData?.text_en}
                onChange={(e) => setFormData({ ...formData, text_en: e })}
              />
            </Col>
            {loading || (id !== "add" && isLoading) ? <Loading /> : ""}
            <Back name="حفظ" onClick={handleSubmitMain} />
          </Row>
        </Col>
      </Container>
    </div>
  );
}
export default AddAdvertisement;
