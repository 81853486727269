import { Route, Routes, useLocation } from "react-router-dom";
import { Message, SuccessMessage } from "./components";
import PopUpImages from "./components/Popup/PopUpImages";
import * as layout from "./Layout";
import * as page from "./pages";
import Chats from "./pages/Chat/Chats";
import GeneralInfo from "./pages/GeneralInfo/GeneralInfo";
import { RequireAuth } from "./Tools/APIs/useMyAPI";
import * as error from "./Tools/Error";
import { Container } from "./Tools/Grid-system";
import { useContextTranslate } from "./Translate/ContextTranslate";

const App = () => {
  const { content } = useContextTranslate();

  const usersSitting = [
    {
      rout: "nationalities",
      title: content.Nationalities,
      name: content.Nationality,
      type: "*",
    },
    {
      rout: "socials-statuses",
      title: content.SocialSituations,
      name: content.SocialSituation,
      type: "*",
    },
    {
      rout: "cities",
      title: content.Cities,
      name: content.City,
      type: "*",
    },
    {
      rout: "skin-colors",
      title: content.SkinColors,
      name: content.SkinColor,
      type: "*",
    },
    {
      rout: "cities",
      title: content.Cities,
      name: content.city,
      type: "*",
    },
    {
      rout: "physiques-statuses",
      title: content.Physiques,
      name: content.Physique,
      type: "*",
    },
    {
      rout: "religiosity-statuses",
      title: content.Religion,
      name: content.Religions,
      type: "*",
    },
    {
      rout: "educational-levels",
      title: content.Degrees,
      name: content.Degree,
      type: "*",
    },
    {
      rout: "financial-statuses",
      title: content.Financial,
      name: content.Financials,
      type: "*",
    },
    {
      rout: "healths-statuses",
      title: content.HealthState,
      name: content.HealthStatus,
      type: "*",
    },
    {
      rout: "prayer-statuses",
      title: content.prayerStatuses,
      name: content.prayerState,
      type: "*",
    },
    {
      rout: "hijab-statuses",
      title: content.hijabStatuses,
      name: content.hijabState,
      type: "FEMALE",
    },
    {
      rout: "smoking-statuses",
      title: content.smokingStatuses,
      name: content.smokingStatue,
      type: "*",
    },
    {
      rout: "beard-statuses",
      title: content.beardStatuses,
      name: content.beardStatue,
      type: "MALE",
    },
    {
      rout: "marriage-statuses",
      title: content.MarriageStatuses,
      name: content.MarriageStatue,
      type: "*",
    },
    {
      rout: "monthly-income-ranges",
      title: content.MonthlyIncomes,
      name: content.MonthlyIncome,
      type: "*",
    },
  ];

  return (
    <>
      <div className="relative flex">
        <layout.SideBar />
        <Message />
        <SuccessMessage />
        <PopUpImages />
        <div className="container mx-auto relative">
          <layout.Navbar />
          <Container>
            <Routes>
              <Route path="login" element={<page.Login />} />
              <Route path="forget-password" element={<page.ForgetPassword />} />
              <Route path="verification-code" element={<page.Code />} />
              <Route path="set-password" element={<page.SetPassword />} />
              <Route path="general-information" element={<GeneralInfo />} />
              <Route element={<RequireAuth />}>
                <Route index element={<page.Home />} />
                <Route path="*" element={<error.P404 />} />
                <Route path="403" element={<error.P403 />} />
                <Route path="500" element={<error.P500 />} />
                <Route path="packages">
                  <Route index element={<page.Packages />} />
                  <Route path="add" element={<page.AddPackage />} />
                  <Route path=":id" element={<page.AddPackage />} />
                </Route>
                <Route path="home">
                  <Route index element={<page.Home />} />
                </Route>
                <Route path="supports">
                  <Route index element={<page.Supports />} />
                </Route>
                <Route path="report">
                  <Route index element={<page.Report />} />
                </Route>
                <Route path={`chat`} element={<Chats />} />
                <Route path="users">
                  <Route index element={<page.Users />} />
                  <Route path=":id" element={<page.AddUsers />} />
                  <Route path="details/:id" element={<page.UserDetails />} />
                </Route>
                <Route path="admin">
                  <Route index element={<page.Admin />} />
                  <Route path=":id" element={<page.AddAdmin />} />
                </Route>
                <Route path="advertisement">
                  <Route index element={<page.Advertisement />} />
                  <Route path=":id" element={<page.AddAdvertisement />} />
                  <Route
                    path="views/:id"
                    element={
                      <page.Views
                        type="views"
                        name={"تقرير المشاهدات"}
                        text={"عدد المشاهدات"}
                      />
                    }
                  />
                  <Route
                    path="clicks/:id"
                    element={
                      <page.Views
                        type="clicks"
                        name={"تقرير النقرات"}
                        text={content.Clicks}
                      />
                    }
                  />
                  <Route path="countries/:id" element={<page.AdsCountries />} />
                  <Route path="position/:id" element={<page.Position />} />
                  <Route
                    path="position/:id/add"
                    element={<page.AddPosition />}
                  />
                  <Route
                    path="countries/:id/:idd"
                    element={<page.AddAdsCountries />}
                  />
                </Route>

                <Route path="countries">
                  <Route
                    index
                    element={
                      <page.Nationalities
                        rout="countries"
                        title={content.Countries}
                        name={content.Country}
                        type="*"
                      />
                    }
                  />
                  <Route
                    path=":id"
                    element={<page.AddNationalities rout="countries" />}
                  />
                  <Route
                    path="cities/:id"
                    element={
                      <page.Cities
                        title={content.Cities}
                        name={content.City}
                        type="cities"
                        rout="countries"
                      />
                    }
                  />
                  <Route
                    path="cities/:id/:idd"
                    element={<page.AddCities type="cities" />}
                  />
                </Route>
                <Route path="employment-statuses">
                  <Route
                    index
                    element={
                      <page.Nationalities
                        rout="employment-statuses"
                        title={content.employmentStatuses}
                        name={content.employmentState}
                        type="*"
                      />
                    }
                  />
                  <Route
                    path=":id"
                    element={
                      <page.AddNationalities rout="employment-statuses" />
                    }
                  />
                  <Route
                    path="jobs/:id"
                    element={
                      <page.Cities
                        title={content.Job}
                        name={content.Jobs}
                        type="jobs"
                        rout="employment-statuses"
                      />
                    }
                  />
                  <Route
                    path="jobs/:id/:idd"
                    element={<page.AddCities type="jobs" />}
                  />
                </Route>
                {usersSitting.map((e, i) => {
                  return (
                    <Route key={i} path={e.rout}>
                      <Route
                        index
                        element={
                          <page.Nationalities
                            rout={e.rout}
                            title={e.title}
                            name={e.name}
                            type={e.type}
                          />
                        }
                      />
                      <Route
                        path=":id"
                        element={
                          <page.AddNationalities rout={e.rout} type={e.type} />
                        }
                      />
                    </Route>
                  );
                })}

                <Route path="support">
                  <Route
                    index
                    element={
                      <page.SupportSections
                        rout="support"
                        type="HELP_CENTER"
                        title={content.problems}
                        name={content.problem}
                        mainTitle={content.Support}
                      />
                    }
                  />
                  <Route
                    path=":id"
                    element={<page.AddSupportSections type="HELP_CENTER" />}
                  />
                  <Route
                    path="questions/:id"
                    element={<page.Support rout="support" type="HELP_CENTER" />}
                  />
                  <Route
                    path="questions/:id/:idd"
                    element={<page.AddSupport type="HELP_CENTER" />}
                  />
                </Route>
                <Route path="common-questions">
                  <Route
                    index
                    element={
                      <page.SupportSections
                        rout="common-questions"
                        type="FAQS"
                        title={content.CommonQuestions}
                        name={content.CommonQuestion}
                        mainTitle={content.CommonQuestions}
                      />
                    }
                  />
                  <Route
                    path=":id"
                    element={<page.AddSupportSections type="FAQS" />}
                  />
                  <Route
                    path="questions/:id"
                    element={
                      <page.Support rout="common-questions" type="FAQS" />
                    }
                  />
                  <Route
                    path="questions/:id/:idd"
                    element={<page.AddSupport type="FAQS" />}
                  />
                </Route>
                <Route path="blog-sections">
                  <Route index element={<page.BlogSections />} />
                  <Route path=":id" element={<page.AddBlogSections />} />
                  <Route path="posts/:id" element={<page.Blog />} />
                  <Route path="posts/:id/:idd" element={<page.AddBlog />} />
                </Route>
                <Route path="notifications" element={<page.Notifications />} />
                <Route
                  path="Change-Password"
                  element={<page.ChangePassword />}
                />
              </Route>
            </Routes>
          </Container>
        </div>
      </div>
    </>
  );
};

export default App;
