import React, { useEffect } from "react";
import { IoMdCloudDone } from "react-icons/io";
import { useContextHook } from "../../Context/ContextOPen";

const SuccessMessage = ({ send }) => {
  const { successMessage, setSuccessMessage } = useContextHook();
  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage([]);
      }, 4000);
    } else {
      setSuccessMessage([]);
    }
  }, [successMessage]);
  return (
    <>
      {
        <div
          className={`fixed transition-all left-7 bg-whit shadow-lg z-[999999999999] rounded-xl overflow-hidden ${
            successMessage === 200 ||
            successMessage === 201 ||
            successMessage?.data?.success ||
            successMessage?.data?.data?.token
              ? "bottom-10 "
              : "-bottom-full"
          }`}
          onClick={() => setSuccessMessage([])}
        >
          <div className="bg-green-500 text-white font-semibold py-5 px-7">
            <IoMdCloudDone size={30} className="mx-auto mb-3" />
            {successMessage === 200 && !send && (
              <p className="mb-2">تم التعديل بنجاح</p>
            )}
            {successMessage === 200 && send && (
              <p className="mb-2">تم الرد بنجاح</p>
            )}
            {successMessage === 201 && (
              <p className="mb-2">تمت الإضافة بنجاح</p>
            )}
            {successMessage?.data?.data?.token && (
              <p className="mb-2">تم تسجيل الدخول بنجاح</p>
            )}
            {successMessage?.data?.success && (
              <p className="mb-2">تم الحذف بنجاح</p>
            )}
          </div>
        </div>
      }
    </>
  );
};

export default SuccessMessage;
