import { useLocation, useParams } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import {
  Add,
  CountryFilter,
  Data,
  MainTitle,
  Table,
  Title,
} from "../../components";
function Views({ type, name, text }) {
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return [`${formattedTime}`, `${formattedDate}`];
  };

  const { id } = useParams();
  const { search } = useLocation();
  const { content } = useContextTranslate();
  const { data, isLoading } = useFETCH(`admin/ads/${id}/${type}${search}`);

  const dataAll = data?.data?.data?.data?.map((e) => {
    return {
      id: e?.ip,
      country: e?.country,
      date: formatDateTime(e?.created_at)[1],
      time: formatDateTime(e?.created_at)[0],
    };
  });

  return (
    <div>
      <MainTitle title={content.Advertisement + " » " + name} />
      <Container>
        <Col col={8}>
          <CountryFilter Input={true} />
        </Col>
        <Row justify="between">
          <Col lg={10} className="bg-white shadow-sm rounded-2xl p-5 mx-auto">
            <Add titles={name} hidBtn={true} />
            <Data
              name={text}
              text={
                data?.data?.views_count
                  ? data?.data?.views_count
                  : data?.data?.clicks_count
              }
            />
            <Table
              thead={[
                content.Id,
                content.Countries,
                content.Date,
                content.Time,
              ]}
              pageCount={Math.ceil(
                data?.data.data.total / data?.data.data.per_page
              )}
              tData={dataAll}
              tbody={["id", "country", "date", "time"]}
              isLoading={isLoading}
              hide={true}
              isAdmin={true}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Views;
