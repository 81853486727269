import React from "react";

const Title = ({ title, className, load }) => {
  return (
    <div className="relative mr-2">
      {load ? (
        <div className="bg-slate-300 animate-pulse w-28 mb-3 mx-5 rounded-sm h-5"></div>
      ) : (
        <div
          className={`text-lg font-semibold text-[#625B5B] border-b-2 border-[#D1C1C1] my-2 pb-2 w-fit ${className}`}
        >
          {title}
        </div>
      )}
    </div>
  );
};

export default Title;
