import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Add, MainTitle, Table } from "../../components";
function SupportSections({ rout, type, mainTitle }) {
  const { content } = useContextTranslate();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/faqs-sections?type=${type}`,
    `admin/faqs-sections`
  );
  const dataAll = data?.data.data.data?.map((e) => {
    return {
      image: fileUrl + e.image?.ar,
      id: e.id,
      name_ar: e.name?.ar,
    };
  });
  return (
    <div>
      <MainTitle title={mainTitle} />
      <Container>
        <Row justify="between">
          <Col
            lg={8}
            md={10}
            className="bg-white shadow-sm rounded-2xl p-5 mx-auto">
            <Add
              titles={content.Categories}
              title={content.Category}
              link={`/${rout}/add`}
            />
            <Table
              thead={[content.Photos, content.Id, content.NameAr]}
              pageCount={Math.ceil(
                data?.data.data.total / data?.data.data.per_page
              )}
              tData={dataAll}
              tbody={["image", "id", "name_ar"]}
              funDelete={deleteItem}
              edit={`${rout}`}
              show={`${rout}/questions`}
              showContent="عرض الأسئلة"
              isLoading={isLoading}
              isAdmin={true}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default SupportSections;
