import { useFETCH, useFilter } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import MultipleSelect from "../Input/MultipleSelect";
import FilterBtnDelete from "./FilterBtnDelete";

function ReportFilter() {
  const { searchParams, handleParamsClick } = useFilter();
  const { data } = useFETCH(`admin/users`);
  const dataAll = data?.data?.data?.data?.map((e) => {
    return {
      id: e?.login_details?.id,
      name: e?.login_details?.username,
    };
  });
  return (
    <div>
      <Row className="items-center">
        <Col lg={3} md={4} sm={6}>
          <FilterBtnDelete />
        </Col>
        <Col lg={3} md={4} sm={6} className="!mb-4">
          <MultipleSelect
            value={searchParams.get("reported_by_id")}
            onChange={(e) => handleParamsClick("reported_by_id", e.value)}
            options={dataAll}
            placeholder="المبلغ"
          />
        </Col>
        <Col lg={3} md={4} sm={6} className="!mb-4">
          <MultipleSelect
            value={searchParams.get("accused_id")}
            onChange={(e) => handleParamsClick("accused_id", e.value)}
            options={dataAll}
            placeholder="المبلغ عنه"
          />
        </Col>
      </Row>
    </div>
  );
}
export default ReportFilter;
