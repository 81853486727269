import { useParams } from "react-router-dom";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { useEffect, useState } from "react";
import { Back, Checkbox, HTMLEditor, Input } from "../../components";
import Loading from "../../Tools/Loading";
import AddImage from "../../components/Input/AddImage";

function AddBlog() {
  const { content } = useContextTranslate();
  const [viewEnImage, setViewEnImage] = useState("");
  const { id, idd } = useParams();
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    viewFile,
    formData,
    loading,
  } = usePOST({ is_special: 0 });
  const { data, isLoading } = useFETCH(
    idd === "add" ? "" : `admin/blog/sections/${id}/posts/${idd}`
  );
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      idd === "add"
        ? `admin/blog/sections/${id}/posts`
        : `admin/blog/sections/${id}/posts/${idd}`
    );
  };
  let dataOld = data?.data.data;

  const handleChangeImage = (e) => {
    const { files, name } = e.target;
    setViewEnImage(URL.createObjectURL(files[0]));
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: files[0],
    }));
  };

  useEffect(() => {
    idd !== "add" &&
      setFormData({
        ...formData,
        _method: "PUT",
        title_ar: dataOld?.title?.ar,
        title_en: dataOld?.title?.en,
        content_ar: dataOld?.content?.ar,
        content_en: dataOld?.content?.en,
        meta_tags_ar: dataOld?.meta_tags?.ar,
        meta_tags_en: dataOld?.meta_tags?.en,
        is_special: dataOld?.is_special,
      });
  }, [dataOld]);
  return (
    <div className="">
      <Container>
        <Row justify="between" className="bg-white shadow-sm rounded-2xl p-5">
          <p className="font-bold text-xl mx-3 mb-5">
            {content.BasicInformation}
          </p>
          <Col md={6}>
            <Input
              name="title_ar"
              onChange={handleChangeInput}
              placeholder={content?.Title + " " + content.Arabic}
              value={formData?.title_ar}
            />
          </Col>
          <Col md={6}>
            <Input
              name="title_en"
              onChange={handleChangeInput}
              placeholder={content?.Title + " " + content.English}
              value={formData?.title_en}
            />
          </Col>
          <p className="text-center text-sm text-gray-500 font-semibold">
            افصل بين الكلمات المفتاحية بفاصلة ,
          </p>
          <Col md={6}>
            <Input
              name="meta_tags_ar"
              onChange={handleChangeInput}
              placeholder={" الكلمات المفتاحية بالعربي "}
              value={formData?.meta_tags_ar}
            />
          </Col>
          <Col md={6}>
            <Input
              name="meta_tags_en"
              onChange={handleChangeInput}
              placeholder={" الكلمات المفتاحية بالانكليزي "}
              value={formData?.meta_tags_en}
            />
          </Col>
          <Col md={6} xs={8} className="">
            <h1 className={`px-3 font-semibold text-start mx-4 mb-2`}>
              إضافة صورة الموقع
            </h1>
            <AddImage
              handleChangeInput={handleChangeInput}
              viewImage={viewFile}
              initialImage={
                dataOld?.image.ar ? fileUrl + dataOld?.image.ar : undefined
              }
              inputName={"image_ar"}
            />
          </Col>
          <Col md={6} xs={8} className="">
            <h1 className={`px-3 font-semibold text-start mx-4 mb-2`}>
              إضافة صورة بالانكليزي
            </h1>
            <AddImage
              handleChangeInput={handleChangeImage}
              viewImage={viewEnImage}
              initialImage={
                dataOld?.image.en ? fileUrl + dataOld?.image.en : undefined
              }
              inputName={"image_en"}
            />
          </Col>
          <Col>
            <HTMLEditor
              title={content?.content + " " + content.Arabic}
              value={formData?.content_ar}
              onChange={(e) => setFormData({ ...formData, content_ar: e })}
            />
          </Col>
          <Col>
            <HTMLEditor
              title={content?.content + " " + content.English}
              value={formData?.content_en}
              onChange={(e) => setFormData({ ...formData, content_en: e })}
            />
          </Col>
          <Col col={3}>
            <Checkbox
              name="is_special"
              checked={formData.is_special}
              onChange={handleChangeInput}
              title="إظهار في الرئيسية"
            />
          </Col>
          {loading || (idd !== "add" && isLoading) ? <Loading /> : ""}
          <Back name="حفظ" onClick={handleSubmitMain} />
        </Row>
      </Container>
    </div>
  );
}
export default AddBlog;
