import { useEffect, useState } from "react";
import { useContextTranslate } from "../Translate/ContextTranslate";
import useItemsData from "./itemsData";
import useRegisterData from "./registerData";
import { gender } from "../Tools/APIs/useMyAPI";

export const useListPage = (genderState, city, job) => {
  const { content } = useContextTranslate();
  const { dataInputs } = useRegisterData(genderState || gender);
  const { itemsData: cityData } = useItemsData("cities", city);
  const { itemsData: jobData } = useItemsData("jobs", job);
  const [advancedSearch, setAdvancedSearch] = useState([]);
  useEffect(() => {
    setAdvancedSearch([
      {
        title: content.MarriageStatuses,
        name: "marriage_status_id",
        options: dataInputs?.marriage_statuses,
      },
      {
        title: content.SocialName,
        name: "social_status_id",
        options: dataInputs?.socials_statuses,
      },
      {
        title: content.Nationalities,
        name: "nationality_id",
        options: dataInputs?.nationalities,
      },
      {
        title: content.Countries,
        name: "country_id",
        options: dataInputs?.countries,
      },
      {
        title: content.City,
        name: "city_id",
        options: cityData,
      },
      {
        title: content.SkinColor,
        name: "skin_color_id",
        options: dataInputs?.skin_colors,
      },
      {
        title: content.Physique,
        name: "physique_status_id",
        options: dataInputs?.physiques_statuses,
      },
      {
        title: content.Degrees,
        name: "educational_level_id",
        options: dataInputs?.educational_levels,
      },
      {
        title: content.Finance,
        name: "financial_status_id",
        options: dataInputs?.financial_statuses,
      },
      {
        title: content.employmentStatuses,
        name: "employment_status_id",
        options: dataInputs?.employment_statuses,
      },
      {
        title: content.Job,
        name: "job_id",
        options: jobData,
      },
      {
        title: content.MonthlyIncome,
        name: "monthly_income_range_id",
        options: dataInputs?.monthly_income_ranges,
      },
      {
        title: content.HealthStatus,
        name: "health_status_id",
        options: dataInputs?.healths_statuses,
      },
      {
        title: content.smokingStatuses,
        name: "smoking_status_id",
        options: dataInputs?.smoking_statuses,
      },
      {
        title:
          genderState === "FEMALE"
            ? content.hijabStatuses
            : content.beardStatuses,
        name: genderState === "FEMALE" ? "hijab_status_id" : "beard_status_id",
        options:
          genderState === "FEMALE"
            ? dataInputs?.hijab_statuses
            : dataInputs?.beard_statuses,
      },
      {
        title: content.prayerStatuses,
        name: "prayer_status_id",
        options: dataInputs?.prayer_statuses,
      },
      {
        title: content.Commitment,
        name: "religiosity_status_id",
        options: dataInputs?.religiosity_statuses,
      },
    ]);
  }, [dataInputs]);

  return {
    advancedSearch,
  };
};
