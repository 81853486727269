import { useFETCH, useFilter } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import MultipleSelect from "../Input/MultipleSelect";
import FilterBtnDelete from "./FilterBtnDelete";

const CountryFilter = ({ Input }) => {
  const { searchParams, handleParamsClick, handleParamsDelete } = useFilter();
  const { data: country } = useFETCH(`admin/lists/countries?type=*`);
  const { data: countryFilterData } = useFETCH(
    "admin/ads/general/views/countries"
  );

 
  const { content } = useContextTranslate();

  const dataAll = country?.data?.data?.items?.map((e) => {
    return {
      id: e?.id,
      name: e.name.en,
    };
    
  });
  
  return (
    <div className="py-3 flex gap-5">
      {Input ? (
        <Row className="items-center w-full">
          <Col lg={3} md={4} sm={6}>
            <FilterBtnDelete />
          </Col>
          <Col lg={3} md={4} sm={6} className="!mb-4">
            <MultipleSelect
              value={searchParams.get("country_id")}
              onChange={(e) => handleParamsClick("country_id", e.value)}
              options={dataAll}
            />
          </Col>
        </Row>
      ) : (
        <select
          value={searchParams.get("country")}
          onChange={(e) => {
            if (e.target.value === content.all) handleParamsDelete("country");
            else handleParamsClick("country", e.target.value);
          }}
          className={`w-full text-center border-2 border-Third text-Third m-1 py-1 mx-5 outline-none rounded-md`}>
          <option>{content.all}</option>
          {countryFilterData?.data?.data?.map((e) => (
            <option>{e}</option>
          ))}
        </select>
      )}
    </div>
  );
};

export default CountryFilter;
