import React from "react";
import Loading from "../../Tools/Loading";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const VictoryChartComp = ({ data, isLoading }) => {
  if (isLoading) return <Loading />;

  const statistics =
    data &&
    data?.countries?.map((e) => ({
      country_name: e.name.ar,
      count: e.users_count,
    }));

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const usersCount = payload[0].value;
      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "10px",
            border: "1px solid #ccc",
          }}
        >
          <p dir="rtl" className="flex gap-2">
            <span>عدد المستخدمين:</span>
            <span className="text-[#8884d8]">{usersCount}</span>
          </p>{" "}
          {/* Views Count */}
        </div>
      );
    }
    return null;
  };

  return (
    !isLoading && (
      <ResponsiveContainer minWidth={1000} width="100%" height={450}>
        <BarChart
          barSize={25}
          width="100%"
          height={450}
          data={statistics}
          style={{}}
        >
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis dataKey="country_name" />
          <YAxis />
          <Tooltip content={CustomTooltip} />
          <Legend formatter={() => "عدد المستخدمين"} />
          <Bar dataKey="count" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    )
  );
};

export default VictoryChartComp;
// ... somewhere else, render it ...
// <BarGraph />
