import { useLocation } from "react-router-dom";
import {
  CountryFilter,
  Data,
  MainTitle,
  Statistics,
  Table,
  Title,
} from "../../components";
import DateFilter from "../../components/Filter/DateFilter";
import VictoryChartComp from "../../components/PoleChart/PoleChart";
import img1 from "../../Svg/Frame 20.svg";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import VisitorsChart from "./VisitorsChart";
import map from "../../images/map.png";
import FilterStatistics from "./FilterStatistics";
import { useState } from "react";

function Home() {
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return [`${formattedTime}`, `${formattedDate}`];
  };
  const { search } = useLocation();
  const [filterUrl, setFilterUrl] = useState();
  const { data: allData, isLoading: isLoadingAll } = useFETCH("admin/home");
  const { data, isLoading } = useFETCH(`admin/ads/general/views${search}`);
  const { data: statData } = useFETCH(filterUrl);
  const { content } = useContextTranslate();

  const stats = filterUrl
    ? statData?.data?.data?.statistics
    : allData?.data?.data?.statistics;

  const dataUsers = (
    search ? data?.data?.data?.data : allData?.data?.data?.views?.data
  )?.map((e) => {
    return {
      id: e?.ip,
      country: e?.country,
      date: formatDateTime(e?.created_at)[1],
      time: formatDateTime(e?.created_at)[0],
    };
  });

  let Cards = [
    {
      img: img1,
      name: content.UsersNumber,
      number: allData?.data?.data?.count?.users,
    },
    {
      img: img1,
      name: content.UsersMaleNumber,
      number: allData?.data?.data?.count?.males,
    },
    {
      img: img1,
      name: content.UsersFemaleNumber,
      number: allData?.data?.data?.count?.females,
    },
  ];

  return (
    <div>
      <Container>
        <MainTitle title={content.Home} />

        <Row>
          {Cards.map((e) => (
            <Col lg={3} md={4} sm={6}>
              <Statistics img={e.img} name={e.name} number={e.number} />
            </Col>
          ))}
        </Row>
        <FilterStatistics setFilterUrl={setFilterUrl} />
        <div className="flex shrink-0 overflow-x-auto justify-center flex-col">
          <h1 className="font-bold mb-4 text-xl font-gray-700">
            احصائيات المستخدمين
          </h1>
          <div dir="ltr" className="w-full">
            <VictoryChartComp data={stats} isLoading={isLoadingAll} />
          </div>
        </div>
        <div className="flex shrink-0 overflow-x-auto justify-center flex-col">
          <h1 className="font-bold mb-4 text-xl font-gray-700">
            أعداد الزوار و المشاهدين
          </h1>
          <div dir="ltr" className="shrink-0 overflow-x-auto">
            <VisitorsChart data={stats} isLoading={isLoadingAll} />
          </div>
        </div>
        <h1 className="font-bold text-xl font-gray-700">
          الزيارات من الاعضاء المسجلين حسب البلد
        </h1>
        <div className="flex gap-8 my-6">
          <img src={map} className="w-1/2" />
          <div className="flex flex-col gap-2 w-1/2">
            <p className="text-lg font-bold flex justify-between w-full items-center">
              <span>البلدان</span>
              <span> المشاهدات</span>
            </p>
            {stats?.views_by_countries?.map((view) => (
              <p className="flex justify-between w-full items-center">
                <span>{view.name.ar}</span>
                <span>{view.views_count}</span>
              </p>
            ))}
          </div>
        </div>
        <Row className="flex-col w-full">
          <Col col={11} className="bg-white shadow-sm rounded-2xl p-5 mx-auto">
            <div className="flex mb-4 flex-col gap-2">
              <div className="flex gap-4 items-center">
                <Title title={content.WebsiteVisits} className="mr-4" />
                <div className="w-[30%]">
                  <CountryFilter />
                </div>
                <div className="w-[30%]">
                  <DateFilter />
                </div>
              </div>
              <Data
                name={content.WebsiteVisits}
                text={data?.data?.data?.total}
              />
            </div>
            <Table
              thead={[
                content.ip,
                content.Countries,
                content.Date,
                content.Time,
              ]}
              tData={dataUsers}
              tbody={["id", "country", "date", "time"]}
              hide={true}
              isLoading={isLoading}
              pageCount={Math.ceil(
                data?.data?.data?.total / data?.data?.data?.per_page
              )}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Home;
