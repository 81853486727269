import { useContextTranslate } from "../../Translate/ContextTranslate";
import Title from "../Title/Title";
import MainButton from "./MainButton";

function Add({ link, title, titles, hidBtn , select }) {
  const { content } = useContextTranslate();
  return (
    <div>
      <div className="flex justify-between items-center flex-wrap px-5">
        <Title title={content.ListOf + " " + titles} />
        {hidBtn ? (
          ""
        ) : (
          <MainButton
            name={content.Add + " " + title}
            className="!px-3 !w-fit text-sm !py-2 !rounded-full"
            link={link}
          /> 
       
        )}
            { select == true ? <h1> Boss </h1> : ""}
         

      </div>
    </div>
  );
}
export default Add;
