import { useLocation } from "react-router-dom";
import { useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Add, MainTitle, MultipleSelect, Table } from "../../components";
function Nationalities({ rout, title, name }) {
  const { pathname } = useLocation();
  const { content } = useContextTranslate();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/lists/${rout}`,
    `admin/lists/${rout}/items`
  );

  const { handleChangeSelect, formData } = usePOST({
    type:
      rout === "beard-statuses"
        ? "MALE"
        : rout === "hijab-statuses"
        ? "FEMALE"
        : "All",
  });

  const dataAll = data?.data?.data?.items
    ?.filter((e) =>
      formData["type"] === "All" ? e : e.type === formData["type"]
    )
    .map((e) => ({
      id: e?.id,
      name_ar: e.name?.ar,
      name_en: e.name?.en,
      type: e.type,
    }));

  const dataGender = [
    {
      id: "All",
      name: "الكل",
    },
    {
      id: "MALE",
      name: "ذكر",
    },
    {
      id: "FEMALE",
      name: "أنثى",
    },
  ];

  const genders = dataGender.map((e) => {
    return {
      id: e?.id,
      name: e.name,
    };
  });

  return (
    <div>
      <Col lg={6} className="flex justify-center">
        <MainTitle title={content.UserSitting + " » " + title} />
        {(pathname === "/socials-statuses" ||
          pathname === "/marriage-statuses") && (
          <MultipleSelect
            name="type"
            value={formData?.type}
            options={genders}
            onChange={handleChangeSelect}
          />
        )}
      </Col>

      {pathname === "/healths-statuses" && (
        <Col lg={6} className="flex gap-1 mx-auto">
          <h1 className="font-bold text-xl">ملاحظة:</h1>
          <h2 className="font-normal text-lg">
            العنصر الأول في هذه القائمة هو العنصر الذي يعبر عن الحالة السليمة
            دوماً، ولا يندرج تحت أيِّ من الحالات الصحية الموجودة
          </h2>
        </Col>
      )}
      <Container>
        <Row justify="between">
          <Col lg={10} className="bg-white shadow-sm rounded-2xl p-5 mx-auto">
            <Add titles={title} title={name} link={`/${rout}/add`} />

            <Table
              thead={
                rout === "socials-statuses" || rout === "marriage-statuses"
                  ? [content.Id, content.NameAr, content.NameEn, content.Gender]
                  : [content.Id, content.NameAr, content.NameEn]
              }
              pageCount={Math.ceil(
                data?.data.data?.total / data?.data.data?.per_page
              )}
              tData={dataAll}
              tbody={
                rout === "socials-statuses" || rout === "marriage-statuses"
                  ? ["id", "name_ar", "name_en", "type"]
                  : ["id", "name_ar", "name_en"]
              }
              funDelete={deleteItem}
              edit={`${rout}`}
              showCity="true"
              show={
                rout === "countries"
                  ? "countries/cities"
                  : rout === "employment-statuses"
                  ? "employment-statuses/jobs"
                  : false
              }
              showContent={
                rout === "countries"
                  ? "عرض المحافظات الخاصة بهذا البلد"
                  : rout === "employment-statuses"
                  ? "عرض الأعمال الخاصة بهذه الوظيفة"
                  : false
              }
              isLoading={isLoading}
              isAdmin={true}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Nationalities;
