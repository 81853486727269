import { useState } from "react";

const MessageSender = ({
  message,
  deleteMessage,
  successfulDelete,
  index,
  id,
}) => {
  // const [newMessageLocal, setNewMessageLocal] = useState(message);

 const language = sessionStorage.getItem('language')
  const [loading, setLoading] = useState(false);

  // const { id } = useParams();
  // useEffect(() => {
  //   if (message.sendMessage) {
  //     setLoading(true);
  //     axios
  //       .post(
  //         `${baseUrl}v1/chat/users/${id}/messages`,
  //         { content: message.content },
  //         {
  //           headers: {
  //             Authorization: "Bearer " + checkToken,
  //             finger_print: browserName + " " + browserVersion,
  //             local: language,
  //           },
  //         }
  //       )
  //       .then((req) => {
  //         //setNewMessage({});
  //         setLoading(false);
  //         if (req?.data?.data?.content) {
  //           setNewMessage({
  //             index: index,
  //             sendMessage: false,
  //             is_sent_by_auth_user: true,
  //             content: req?.data?.data?.content,
  //             created_at: req?.data?.data?.created_at,
  //             receiver_id: req?.data?.data?.receiver_id,
  //             sender_id: req?.data?.data?.sender_id,
  //             id: req?.data?.data?.id,
  //             updated_at: req?.data?.data?.created_at,
  //             key: message.key,
  //           });
  //           // message = {
  //           //   sendMessage: false,
  //           //   is_sent_by_auth_user: true,
  //           //   content: req?.data?.data.content,
  //           //   created_at: req?.data?.data.created_at,
  //           //   receiver_id: req?.data?.data.receiver_id,
  //           //   sender_id: req?.data?.data.sender_id,
  //           //   id: req?.data?.data.id,
  //           //   updated_at: req?.data?.data.created_at,
  //           // };

  //           // setNewMessageLocal({
  //           //   sendMessage: false,
  //           //   is_sent_by_auth_user: true,
  //           //   content: req?.data?.data.content,
  //           //   created_at: req?.data?.data.created_at,
  //           //   receiver_id: req?.data?.data.receiver_id,
  //           //   sender_id: req?.data?.data.sender_id,
  //           //   id: req?.data?.data.id,
  //           //   updated_at: req?.data?.data.created_at,
  //           // });
  //         }
  //       })
  //       .catch((e) => {
  //         setLoading(false);
  //       });
  //   }
  // }, []);
  return (
    <>
      {
        <div
          className={`max-w-[60%] min-w-[90px] w-fit bg-Secondary px-2 pt-1 ${
              "ml-auto rounded-tr-none"
          }  relative text-white rounded-md  `}>
          <span
            className={`absolute bg-black top-0 ${
                "-right-[16px] border_chat-en"
            } `}
          />
          <div className="">
            <div
              className={`flex items-start justify-between gap-4 w-full ${
                language === "ar" ? "flex-row-reverse" : ""
              }`}>
              <div className="flex flex-wrap">
                <pre
                  className="whitespace-pre-wrap"
                  style={{ wordBreak: "break-word" }}>
                  {message.content}
                </pre>
              </div>
              {/* <div col={1} className="w-2">
                <MessageSenderOption
                  successfulDelete={successfulDelete}
                  deleteMessage={deleteMessage}
                  content={message.content}
                />
              </div> */}
            </div>
            <div className="flex items-center justify-end gap-3 ">
              <div>
                <span className="flex items-center justify-end gap-1">
                  <div className="f_number text-[12px] text-end">
                    {new Date(message.created_at).toLocaleTimeString()}
                  </div>
                </span>
              </div>
              {/* <div col={1} className="w-2">
                {loading ? (
                  <MdOutlineUpdate
                    size={15}
                    className=" text-slate-400 animate-spin"
                  />
                ) : message.read_at || messageRead ? (
                  <MdDoneAll size={15} className=" text-slate-400" />
                ) : (
                  <MdDone size={15} className=" text-slate-400" />
                )}
              </div> */}
            </div>
          </div>
        </div>
      }
    </>
  );
};
export default MessageSender;
