import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Back, Input, TitleEdit } from "../../components";
function AddBlogSections() {
  const { content } = useContextTranslate();
  const { id } = useParams();
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    formData,
    loading,
    error,
  } = usePOST({});
  const { data, isLoading } = useFETCH(
    id === "add" ? "" : `admin/blog/sections/${id}`
  );
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      id === "add" ? `admin/blog/sections` : `admin/blog/sections/${id}`
    );
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        ...formData,
        _method: "PUT",
        name_ar: dataOld?.name?.ar,
        name_en: dataOld?.name?.en,
      });
  }, [dataOld]);
  return (
    <div>
      <Container>
        <Col md={6} className="mx-auto mt-8">
          <Row className="bg-white shadow-sm rounded-2xl p-5">
            <TitleEdit />
            <Col>
              <Input
                name="name_ar"
                onChange={handleChangeInput}
                placeholder={content?.NameAr}
                value={formData?.name_ar}
              />
            </Col>
            <Col>
              <Input
                name="name_en"
                onChange={handleChangeInput}
                placeholder={content?.NameEn}
                value={formData?.name_en}
              />
            </Col>
            {loading || (id !== "add" && isLoading) ? <Loading /> : ""}
            <Back name="حفظ" onClick={handleSubmitMain} />
          </Row>
        </Col>
      </Container>
    </div>
  );
}
export default AddBlogSections;
