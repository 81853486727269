import { BiMenu } from "react-icons/bi";
import { useContextHook } from "../../Context/ContextOPen";
import ImageList from "./ImageList";
import NotificationNew from "./NotificationNew";
const Navbar = () => {
  const { setOpenMenu, openMenu } = useContextHook();
  return (
    <>
      <div className="sticky lg:mx-12 bg-[#fff4ea] top-0 z-20 flex items-center max-sm:flex-wrap max-sm:gap-3 justify-between px-8 py-2">
        <BiMenu
          size={40}
          className="cursor-pointer text-Main lg:hidden"
          onClick={() => setOpenMenu(!openMenu)}
        />
        <div className="max-sm:order-3">
          <ImageList />
        </div>
        <div className="max-sm:order-1">
          <NotificationNew />
        </div>
      </div>
    </>
  );
};

export default Navbar;
