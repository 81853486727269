import { Link, useLocation } from "react-router-dom";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import Pagination from "../../Tools/Pagination";
import { Back, Title } from "../../components";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
const Notifications = () => {
  const { search } = useLocation();
  const { data, isLoading } = useFETCH(
    `admin/notifications${search}`,
    "admin/notifications"
  );
  return (
    <Container>
      <section className="py-4 min-h-[50vh]">
        <Title title="الإشعارات" />
        <>
          {isLoading ? <Loading /> : ""}
          {data?.data.data.data.length === 0 ? (
            <div className="h-[60vh] flex justify-center items-center text-2xl font-semibold text-center">
              <p className="border-2 border-Secondary rounded-xl px-3 py-8 bg-white">
                لا يوجد إشعارات ليتم عرضها
              </p>
            </div>
          ) : (
            <Pagination
              pageCount={Math.ceil(
                data?.data.data.total / data?.data.data.per_page
              )}
            >
              <Row justify="center">
                <Col
                  md={8}
                  className="bg-white rounded-xl space-y-1 overflow-hidden"
                >
                  {data?.data.data.data.map((e, i) => (
                    <div
                      key={i}
                      className={`${
                        +e.has_read !== 1 ? " bg-green-100" : ""
                      } flex justify-between items-start   border-b p-2 border-Third `}
                    >
                      {e.clickable === 1 ? (
                        <Link
                          to={
                            e.state === 7
                              ? "/report"
                              : e.state === 8
                              ? "/users/details/" + e.model_id
                              : ""
                          }
                        >
                          <div className="text- text-Main font-bold py-2">
                            {e.title}
                          </div>
                          <div className="">{e.description}</div>
                          <div className="  text-sm font-semibold pt-2">
                            {e.created_at?.substring(0, 20)}
                          </div>
                        </Link>
                      ) : (
                        <>
                          <div className="text- text-Main font-bold py-2">
                            {e.title}
                          </div>
                          <div className="mb-3">{e.description}</div>
                          <div className="  text-sm font-semibold pt-2">
                            {e.created_at?.substring(0, 20)}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </Col>
              </Row>
            </Pagination>
          )}
        </>
        <Back />
      </section>
    </Container>
  );
};

export default Notifications;
