import axios from "axios";
import { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
import Select from "react-select";
import { MainButton, SliderMUI } from "../../components";
import { useListPage } from "../../Context/listSignUp";
import {
  baseUrl,
  gender,
  useClose,
  useFilter,
} from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../Translate/ContextTranslate";

const PopUpAdvancedSearch = ({ total, user }) => {
  const isAdmin = user?.role?.name === "super_admin";
  const { content } = useContextTranslate();
  const {
    searchParams,
    handleParamsClick,
    handleParamsDeleteAll,
    handleParamsDelete,
  } = useFilter();
  const [optionsCities, setOptionsCities] = useState([]);

  useEffect(() => {
    setOptionsCities([]);
    searchParams
      .get("country_id")
      ?.split(",")
      ?.forEach((e) => {
        axios
          .get(`${baseUrl}admin/lists/cities?type=*&parent_item_id=${e}`)
          .then((req) => {
            if (req) {
              req?.data?.data?.items.map((ep) =>
                setOptionsCities((prevOptionsCities) => [
                  ...prevOptionsCities,
                  ep,
                ])
              );
            }
          });
      });
  }, [searchParams.get("country_id")]);
  const [optionsJobs, setOptionsJobs] = useState([]);
  useEffect(() => {
    setOptionsJobs([]);
    searchParams
      .get("employment_status_id")
      ?.split(",")
      ?.forEach((e) => {
        axios
          .get(`${baseUrl}admin/lists/jobs?type=*&parent_item_id=${e}`)
          .then((req) => {
            if (req) {
              req?.data?.data?.items.map((ep) =>
                setOptionsJobs((prevOptionsCities) => [
                  ...prevOptionsCities,
                  ep,
                ])
              );
            }
          });
      });
  }, [searchParams.get("employment_status_id")]);
  const [genderView, setGenderView] = useState("MALE");
  const { advancedSearch } = useListPage(
    genderView,
    optionsCities,
    optionsJobs
  );

  const { mouse, open, setOpen } = useClose();
  const [valueSearch, setValueSearch] = useState({
    age: [18, 50],
    weight: [45, 120],
    height: [150, 190],
    children: "0",
    gender: genderView,
  });
  const handleChangeRange = (event, newValue) => {
    setValueSearch({
      ...valueSearch,
      [event.target?.name]: newValue,
    });
  };

  const handleChangeMultiSelect = (selected, active) => {
    setValueSearch({
      ...valueSearch,
      [active?.name]: selected,
    });
    handleParamsClick(
      active?.name,
      Array.isArray(selected) ? selected?.map((e) => e.value) : selected.value
    );
  };
  const handleChangeGender = (gender) => {
    setGenderView(gender);
    setValueSearch({ ...valueSearch, gender });
  };

  useEffect(() => {
    if (open) {
      if (valueSearch.age[0] && valueSearch.age[1]) {
        handleParamsClick("age_more_than", valueSearch.age[0]);
        handleParamsClick("age_less_than", valueSearch.age[1]);
      }
      if (valueSearch.weight[0] && valueSearch.weight[1]) {
        handleParamsClick("weight_more_than", valueSearch.weight[0]);
        handleParamsClick("weight_less_than", valueSearch.weight[1]);
      }
      if (valueSearch.height[0] && valueSearch.height[1]) {
        handleParamsClick("height_more_than", valueSearch.height[0]);
        handleParamsClick("height_less_than", valueSearch.height[1]);
      }
      if (valueSearch.children) {
        handleParamsClick("children", valueSearch.children);
      } else handleParamsDelete("children");
      handleParamsClick("gender", genderView);
    }
  }, [valueSearch]);

  useEffect(() => {
    handleParamsDeleteAll();
    setGenderView("MALE");
  }, []);

  return (
    <>
      {open && (
        <div className="bg-black/20 fixed top-0 left-0 w-full h-full z-40" />
      )}
      <div ref={mouse}>
        <MainButton
          className="px-3 py-2"
          onClick={() => setOpen(!open)}
          name={
            <div className="flex items-center gap-2 max-sm:text-sm text-base">
              {content.Filter}
              <FaFilter />
            </div>
          }
        />
        {open && (
          <div className="absolute chat top-14 left-1/2 -translate-x-1/2 w-[90%] max-h-[80vh] overflow-y-auto sidebar md:w-1/2 z-50">
            <form>
              <Row justify="center">
                <Col>
                  <div className="px-10 bg-white rounded-tl-[50px] rounded-br-[50px] border shadow-xl p-6">
                    {isAdmin && (
                      <div className="rounded-xl text-sm overflow-hidden w-fit mx-auto mb-4 space-x-3">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleChangeGender("MALE");
                          }}
                          className={`${
                            genderView === "MALE"
                              ? "text-white bg-Secondary"
                              : "text-Secondary bg-white"
                          } transition-all duration-300 font-semibold rounded-xl px-5 py-3`}
                        >
                          MALE
                        </button>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleChangeGender("FEMALE");
                          }}
                          className={`${
                            genderView === "FEMALE"
                              ? "text-white bg-Main"
                              : "text-Main bg-white"
                          } transition-all duration-300 font-semibold rounded-xl px-5 py-3`}
                        >
                          FEMALE
                        </button>
                      </div>
                    )}
                    <Row>
                      {advancedSearch?.map((e) => (
                        <Col md={6}>
                          <div className="font-semibold">{e.title}</div>
                          <Select
                            className="w-full bg-[#E7E7E7] mt-2 rounded-lg text-center"
                            isMulti
                            onChange={handleChangeMultiSelect}
                            value={valueSearch?.[e.name]}
                            name={e.name}
                            options={e.options?.map((e) => {
                              return {
                                value: e.id,
                                label: e.name?.ar ? e.name?.ar : e.name,
                              };
                            })}
                          />
                        </Col>
                      ))}
                      <Col md={6}>
                        <div className=" font-semibold">بحث حسب</div>
                        <Select
                          isSearchable={false}
                          className="w-full bg-[#E7E7E7] mt-2 rounded-lg text-center"
                          onChange={handleChangeMultiSelect}
                          name="sort_by"
                          options={[
                            { value: "age", label: "العمر" },
                            {
                              value: "counter_reports",
                              label: "حسب الأكثر إبلاغاً عنه",
                            },
                            { value: "last_active_at", label: "أخر ظهور" },
                            {
                              value: "created_at",
                              label: "تاريخ التسجيل",
                            },
                          ]}
                        />
                      </Col>
                      <Col md={6}>
                        <SliderMUI
                          title={content.Children}
                          unit=""
                          max={12}
                          value={valueSearch?.children}
                          onChange={handleChangeRange}
                          name="children"
                        />
                      </Col>
                      <Col md={6}>
                        <SliderMUI
                          title={content.Age}
                          unit={content.years}
                          max={70}
                          min={18}
                          value={valueSearch?.age}
                          onChange={handleChangeRange}
                          name="age"
                        />
                      </Col>
                      <Col md={6}>
                        <SliderMUI
                          title={content.Weight}
                          unit="kg"
                          max={200}
                          min={18}
                          value={valueSearch?.weight}
                          onChange={handleChangeRange}
                          name="weight"
                        />
                      </Col>
                      <Col md={6}>
                        <SliderMUI
                          title={content.Height}
                          unit="cm"
                          max={220}
                          min={100}
                          value={valueSearch?.height}
                          onChange={handleChangeRange}
                          name="height"
                        />
                      </Col>
                    </Row>
                    <div className="flex mt-5">
                      <div
                        onClick={() => setOpen(false)}
                        className={` px-4 w-fit mx-auto mb-5 transition-all font-semibold   py-2 text-white cursor-pointer bg-Main flex items-center justify-center rounded-lg rounded-br-lg gap-2  `}
                      >
                        {"عرض " + total}
                      </div>
                      <div
                        onClick={(e) => {
                          handleParamsDeleteAll();
                          setValueSearch({
                            age: [18, 50],
                            weight: [45, 120],
                            height: [150, 190],
                            children: "0",
                            marriage_status_id: [],
                            social_status_id: [],
                            nationality_id: [],
                            country_id: [],
                            city_id: [],
                            skin_color_id: [],
                            physique_status_id: [],
                            educational_level_id: [],
                            financial_status_id: [],
                            employment_status_id: [],
                            job_id: [],
                            monthly_income_range_id: [],
                            health_status_id: [],
                            smoking_status_id: [],
                            hijab_status_id: [],
                            beard_status_id: [],
                            prayer_status_id: [],
                            religiosity_status_id: [],
                            gender: "",
                          });
                          setOpen(false);
                        }}
                        className={`px-4 w-fit mx-auto mb-5 transition-all font-semibold py-2 text-white cursor-pointer bg-Secondary flex items-center justify-center rounded-lg rounded-br-lg gap-2  `}
                      >
                        إلغاء الكل
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default PopUpAdvancedSearch;
