import { useLocation } from "react-router-dom";
import { useFilter } from "../../Tools/APIs/useMyAPI";

function FilterBtnDelete() {
  const { search } = useLocation();
  const { handleParamsDeleteAll } = useFilter();
  return (
    <div
      className={`border bg-blue-600 border-blue-600 text-center py-[0.4rem] rounded-md
       ${search ? "!bg-white text-blue-600 " : "bg-blue-600 text-white"} `}
      onClick={() => handleParamsDeleteAll()}
    >
      الكل
    </div>
  );
}
export default FilterBtnDelete;
