import { useParams } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Add, MainTitle, Table } from "../../components";
function Position() {
  const { id } = useParams();
  const { content } = useContextTranslate();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/ads/${id}/positions`,
    `admin/ads/${id}/positions`,
    true
  );
  const dataAll = data?.data?.data?.map((e) => {
    return {
      id: e?.id,
      display: e.display,
      name: e?.name,
    };
  });
  return (
    <div>
      <MainTitle title={content.Advertisement + " » " + content.Positions} />
      <Container>
        <Row justify="between">
          <Col lg={10} className="bg-white shadow-sm rounded-2xl p-5 mx-auto">
            <Add
              titles={content.Positions}
              title={content.Position}
              link={`/advertisement/position/${id}/add`}
            />
            <Table
              thead={[content.Id, content.Name]}
              pageCount={Math.ceil(
                data?.data.data.total / data?.data.data.per_page
              )}
              tData={dataAll}
              tbody={["id", "display"]}
              isLoading={isLoading}
              funDelete={deleteItem}
              isAdmin={true}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Position;
