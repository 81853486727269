import React, { useEffect, useState } from "react";
import { useFilter } from "../../Tools/APIs/useMyAPI";
import Input from "../Input/Input";

const UsernameSearch = () => {
  const { handleParamsClick, handleParamsDeleteAll } = useFilter();
  const [searchTerm, setSearchTerm] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      handleParamsDeleteAll();
      handleParamsClick("search", value);
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return (
    <div>
      <Input
        className="md:w-[70%] w-full"
        placeholder="اسم المستخدم"
        onChange={handleChange}
        value={searchTerm}
      />
    </div>
  );
};

export default UsernameSearch;
