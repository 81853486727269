import { useEffect, useState } from "react";
import { useFETCH } from "../Tools/APIs/useMyAPI";

const useRegisterData = (type) => {
  const { data, isLoading } = useFETCH(`admin/users/input/lists?type=${type}`);
  const [dataInputs, setDataInputs] = useState(null);

  useEffect(() => {
    setDataInputs(data?.data?.data);
  }, [data]);

  return { dataInputs, isLoading };
};

export default useRegisterData;
