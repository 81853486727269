import Select from "react-select";
import { HiQuestionMarkCircle } from "react-icons/hi";
import IconHover from "../IconHover/IconHover";
const MultipleSelect = ({
  text,
  title,
  value,
  onChange,
  name,
  isSearch,
  options,
  icon,
  className,
  placeholder,
}) => {
  return (
    <div className={`${className} my-1`}>
      <div className="flex items-center">
        <div className="px-3 font-semibold">{text || title}</div>
        {icon && (
          <div className="w-fit">
            <IconHover
              id="gender"
              text="سيظهر مستخدمين من الجنس المقابل للجنس الذي قمت باختياره">
              <div
                className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}>
                <HiQuestionMarkCircle size={25} />
              </div>
            </IconHover>
          </div>
        )}
      </div>
      <Select
        value={
          (value &&
            options
              ?.filter((e) => `${e.id}` === `${value}`)
              ?.map((q) => {
                return { value: q.id, label: q.name };
              })[0]) ||
          null
        }
        isSearchable={isSearch || true}
        className="w-full bg-[#E7E7E7] mt-2 rounded-lg text-center"
        onChange={onChange}
        name={name}
        options={options?.map((e) => {
          return { value: e.id, label: e.name };
        })}
        placeholder={placeholder}
      />
    </div>
  );
};

export default MultipleSelect;
