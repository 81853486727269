import React, { useRef } from "react";
import { RiEditBoxLine } from "react-icons/ri";

export default function AddImage({
  initialImage,
  viewImage,
  handleChangeInput,
  inputName,
}) {
  const inputImageRef = useRef(null);

  return (
    <div className="w-full">
      <input
        type="file"
        hidden
        name={inputName}
        ref={inputImageRef}
        accept="image/*"
        onChange={handleChangeInput}
      />
      {viewImage || initialImage ? (
        <div className="space-y-2">
          <div className="relative w-60 md:w-80 mx-auto">
            <img
              src={viewImage ? viewImage : initialImage}
              className="w-full rounded-md"
            />
            <div className="absolute left-2 top-2 flex gap-4">
              <div
                onClick={(e) => {
                  inputImageRef?.current.click();
                  e.preventDefault();
                }}
              >
                <button className="bg-green-500 p-1 rounded">
                  <RiEditBoxLine color="white" size={20} />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <button
          onClick={(e) => {
            inputImageRef?.current.click();
            e.preventDefault();
          }}
          className={`${
            viewImage && "hidden"
          } rounded-lg border w-full border-blue-600 bg-white text-blue-600 transition-colors p-2`}
        >
          قم بتحميل صورة من جهازك
        </button>
      )}
    </div>
  );
}
