import { useEffect, useRef, useState } from "react";
import { useFETCH, useFilter } from "../../Tools/APIs/useMyAPI";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { FaArrowDown } from "react-icons/fa";
import MessageRecipient from "./MessageRecipient";
import MessageSender from "./MessageSender";

export const Chats = ({ id }) => {
  const language = sessionStorage.getItem("language");
  const { content } = useContextTranslate;
  const { searchParams } = useFilter();
  const accusedId = searchParams.get("accused_id");
  const reported_by = searchParams.get("reported_by");

  // const [filter, setFilter] = useStateuseFilter(1);
  const [refresh, setRefresh] = useState(0);
  // const { newMessage, setNewMessage, chatDelelete, setMessageRead } =
  //   useContextChatuseFilter();
  const distance = useRef(0);
  // const disntanceInPercent = useRefuseFilter(0);
  const [messages, setMessages] = useState([]);
  // const { pathname } = useLocationuseFilter();
  // const { id } = useParams();

  const scrollContainerRef = useRef(0);
  let scrollHeight = useRef(0);
  let scrollTop = useRef(0);

  // let filterSelector = useRef(filter);

  // let { data, isLoading, deleteItem, successfulDelete } = useFETCH(
  //   `v1/chat/users/${id}/messages?page=${filterSelector.current}`,
  //   `v1/chat/users/${id}/messages`,
  //   false,
  //   true,
  //   true,
  //   refresh,
  //   true
  // );

  const { data, isLoading } = useFETCH(
    `admin/chat/users/${searchParams.get(
      "accused_id"
    )}/messages?reported_by=${searchParams.get("reported_by")}`
  );
  // useEffect(()=>{
  //     const interval = setInterval(() =>{
  //       filterSelctor = 1;
  //        setRefresh((prevRefresh)=> prevRefresh + 1);

  //        clearInterval(interval);
  //       }, 10000)

  // }, [refresh]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWindowWidth(window.innerWidth);
  //     setWindowHeight(window.innerHeight);
  //   };

  //   window.addEventListener("resize", handleResize);
  //   distance.current = disntanceInPercent.current * windowHeight;
  //   scrollHeight.current = scrollContainerRef.current.scrollHeight;
  //   scrollTop.current = scrollContainerRef.current.scrollTop;

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [windowWidth, windowHeight]);

  useEffect(() => {
    setMessages((prevMessages) => {
      if (data?.data?.data?.current_page > 1) {
        data?.data?.data?.data?.toReversed().map((value) => {
          prevMessages.push(value);
        });
        return prevMessages;
      } else {
        const prevMessagesCopy = [...prevMessages];
        const dataCopy = [...(data?.data?.data?.data || [])];
        let index = 0;
        let prevIndex = 0;
        while (
          prevIndex < prevMessagesCopy.length &&
          prevMessagesCopy[prevIndex].id == null
        ) {
          prevIndex++;
        }
        while (prevIndex < 100 && index < dataCopy.length) {
          if (prevMessagesCopy.length === prevIndex) {
            prevMessagesCopy.push(dataCopy[index]);
          } else {
            prevMessagesCopy[prevIndex] = dataCopy[index];
          }

          prevIndex++;
          index++;
        }

        return prevMessagesCopy;
      }
    });
    // if (data?.data?.data?.current_page === 1) {
    //   const interval = setInterval(() => {
    //     filterSelector.current = 1;
    //     setRefresh((prevRefresh) => prevRefresh + 1);
    //     clearInterval(interval);
    //   }, 5000);
    // }

    // });
  }, [data?.data?.data?.data]);

  // useEffect(() => {
  //   setMessages([]);
  //   setMessageRead("");
  //   setFilter(1);
  // }, [id, pathname]);

  // useEffect(() => {
  //   if (chatDelelete === id) {
  //     setMessages([]);
  //     setFilter(1);
  //   }
  // }, [chatDelelete]);

  function updateMessageByKey(key, updatedMessage) {
    setMessages((prevMessages) =>
      prevMessages.map((message) => {
        if (message.key === key) {
          return updatedMessage;
        }
        return message;
      })
    );
  }
  // useEffect(() => {
  //   if (newMessage?.content) {
  //     if (newMessage.id) {
  //       updateMessageByKey(newMessage.key, newMessage);
  //       setNewMessage({});
  //     } else {
  //       setMessages((prevMessages) => [newMessage, ...prevMessages]);
  //     }
  //   }
  //   scrollHeight.current = scrollContainerRef.current.scrollHeight;
  //   scrollTop.current = scrollContainerRef.current.scrollTop;
  // }, [newMessage]);

  useEffect(() => {
    if (scrollHeight.current - (distance.current + scrollTop.current) < 1) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
    scrollHeight.current = scrollContainerRef.current.scrollHeight;
    scrollTop.current = scrollContainerRef.current.scrollTop;
  }, [messages]);

  // useEffect(() => {
  //   distance.current = scrollContainerRef.current.scrollHeight;
  //   scrollHeight.current = scrollContainerRef.current.scrollHeight;
  //   scrollTop.current = scrollContainerRef.current.scrollTop;
  //   disntanceInPercent.current = distance.current / windowHeight;
  // }, []);

  return (
    <div className="md:relative">
      {/* <NavChat id={id} /> */}
      <div
        // onScroll={(e) => {
        //   if (data?.data?.data?.data?.length !== 0) {
        //     if (e.target.scrollTop === 0) {
        //       filterSelector.current = filter + 1;
        //       setFilter(filter + 1);
        //     }
        //   }
        // }}
        ref={scrollContainerRef}
        className="pb-[12.5vh] max-md:pb-0 w-full flex-1 h-[80vh] space-y-1 chat overflow-y-scroll scroll-smooth max-md:px-5 md:px-16 bg-slate-200 py-3 text-black text-opacity-60 chat text-lg chat-background"
      >
        {isLoading && (
          <div className="flex justify-center items-center">
            <h1 className="text-3xl font-bold">Loading...</h1>
          </div>
        )}
        {renderMessages(
          messages,
          isLoading,
          id,
          "",
          "",
          accusedId,
          reported_by
        )}
        {scrollHeight.current -
          (distance.current + scrollContainerRef.current.scrollTop) >
          100 && (
          <FaArrowDown
            color="white"
            onClick={() => {
              scrollContainerRef.current.scrollTop =
                scrollContainerRef.current.scrollHeight;
            }}
            size={40}
            className={`bg-Main p-2 rounded-full cursor-pointer bottom-[14%] fixed ${"right-[90%] max-md:right-2"} `}
          />
        )}
      </div>
    </div>
  );
};

export default Chats;

const renderMessages = (
  messages,
  deleteItem,
  successfulDelete,
  isLoading,
  userId,
  accusedId,
  reported_by
) => {
  let currentDate = null;
  if (messages.length === 0 && !isLoading) {
    return (
      <div className="w-full h-full bg-slate-200 flex justify-center items-center text-xl font-bold  text-black text-opacity-60">
        {/* {content.noMessages} */} No Messages
      </div>
    );
  }
  // let index = 0;
  return messages?.toReversed().map((message) => {
    if (currentDate !== new Date(message.created_at).toLocaleDateString()) {
      currentDate = new Date(message.created_at).toLocaleDateString();
      return (
        <div key={message.id} className="pb-1">
          <div className="mx-auto px-2 py-1 my-2 bg-Secondary w-fit rounded-xl text-white font-semibold text-md">
            {currentDate}
          </div>
          {message.sender_id == reported_by ? (
            <MessageSender
              message={message}
              // deleteMessage={() =>
              //   deleteItem(
              //     message?.id,
              //     `v1/chat/users/${userId}/messages/${message?.id}`
              //   )
              // }
              // successfulDelete={successfulDelete}
              // index={index++}
            />
          ) : (
            <MessageRecipient message={message} />
          )}
        </div>
      );
    }
    return (
      <div key={message.id} className="pb-1">
        {message.sender_id == reported_by ? (
          <MessageSender
            message={message}
            // deleteMessage={() =>
            //   deleteItem(
            //     message.id,
            //     `v1/chat/users/${userId}/messages/${message.id}`
            //   )
            // }
            // successfulDelete={successfulDelete}
            // index={index++}
          />
        ) : (
          <MessageRecipient message={message} />
        )}
      </div>
    );
  });
};
