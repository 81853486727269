import { useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Back, TitleEdit } from "../../components";

function AddAdsCountries() {
  const { id } = useParams();
  const { handleSubmit, loading, setCheckArray } = usePOST({});
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(`admin/ads/${id}/countries`);
  };
  const { data } = useFETCH(`admin/lists/countries?type=*`);
  const [selectCountries, setSelectCountries] = useState([]);
  const CountriesOption = data?.data?.data?.items?.map((e) => {
    return { value: e.id, label: e.name?.ar };
  });
  // const selectAllOption = { value: "0", label: "حدد الكل" };
  // const options = [selectAllOption, ...(CountriesOption || [])];
  return (
    <div>
      <Container>
        <Col lg={8} md={10} className="mx-auto">
          <Row justify="center" className="bg-white shadow-sm rounded-2xl p-5">
            <TitleEdit />
            <Col>
              <h1 className="text-start mb-2">أضف بلد</h1>
              <Select
                closeMenuOnSelect={false}
                isMulti
                name="countries_ids[]"
                value={selectCountries}
                onChange={(selectedOptions) => {
                  const selectedValues = selectedOptions?.map((option) => [
                    option.value,
                  ]);
                  setSelectCountries(selectedOptions);
                  setCheckArray({ "countries_ids[]": selectedValues });
                }}
                options={CountriesOption}
                // options={options}
              />
            </Col>
            {loading ? <Loading /> : ""}
            <Back name="حفظ" onClick={handleSubmitMain} />
          </Row>
        </Col>
      </Container>
    </div>
  );
}
export default AddAdsCountries;
