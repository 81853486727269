import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Back, Input, TitleEdit } from "../../components";
import AddImage from "../../components/Input/AddImage";
import { FEATURES } from ".";

function AddPackage() {
  const { content } = useContextTranslate();
  const { id } = useParams();
  const [features, setFeatures] = useState([]);
  const { handleChangeInput, handleSubmit, viewFile, formData } = usePOST({
    parent_item_id: id,
    type: "*",
  });
  const { data } = useFETCH(id ? `admin/packages/${id}` : "");
  const handleSubmitMain = (e) => {
    e.preventDefault();
    const formDataToSend = { ...formData, features: features.join() };
    handleSubmit(
      id ? `admin/packages/${id}` : `admin/packages`,
      false,
      false,
      true,
      formDataToSend
    );
  };
  let dataOld = data?.data?.data;

  console.log(features);

  useEffect(() => {
    if (data) {
      setFeatures(dataOld.features.split(","));
    }
  }, [data]);

  return (
    <div>
      <Container>
        <Col md={6} className="mx-auto">
          <Row justify="center" className="bg-white shadow-sm rounded-2xl p-5">
            <TitleEdit />
            <Col>
              <AddImage
                handleChangeInput={handleChangeInput}
                inputName={"image"}
                viewImage={viewFile}
                initialImage={dataOld ? fileUrl + dataOld.image : undefined}
              />
            </Col>
            <Col>
              <h1 className={`px-3 font-semibold text-start mx-4 mb-2`}>
                الميزات
              </h1>
              {FEATURES.map((feature) => (
                <label className="flex cursor-pointer py-1 items-center gap-4">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      if (checked) {
                        setFeatures((prev) => [...prev, feature.key]);
                      } else {
                        setFeatures((prev) =>
                          prev.filter((f) => f !== feature.key)
                        );
                      }
                    }}
                    name={feature.name}
                    className="w-fit"
                    defaultChecked={dataOld?.features
                      ?.split(",")
                      .includes(feature.key)}
                  />
                  {feature.name}
                </label>
              ))}
            </Col>
            <Col>
              <Input
                name="name_ar"
                onChange={handleChangeInput}
                placeholder={content?.NameAr}
                defaultValue={dataOld?.ar_name}
              />
            </Col>
            <Col>
              <Input
                name="name_en"
                onChange={handleChangeInput}
                placeholder={content?.NameEn}
                defaultValue={dataOld?.en_name}
              />
            </Col>
            <Col>
              <Input
                name="duration"
                type={"number"}
                onChange={handleChangeInput}
                defaultValue={dataOld?.duration}
                placeholder={"المدة بالأيام"}
              />
            </Col>
            <Col>
              <Input
                name="old_price"
                onChange={handleChangeInput}
                placeholder={"السعر القديم"}
                defaultValue={dataOld?.old_price}
              />
            </Col>
            <Col>
              <Input
                name="new_price"
                onChange={handleChangeInput}
                placeholder={"السعر الجديد"}
                defaultValue={dataOld?.new_price}
              />
            </Col>
            <Back name="حفظ" onClick={handleSubmitMain} />
          </Row>
        </Col>
      </Container>
    </div>
  );
}
export default AddPackage;
