import { useClose } from "../../Tools/APIs/useMyAPI";

function Filter({ text, children, onclick, className, classNameBut }) {
  const { open, setOpen, mouse } = useClose();
  return (
    <div ref={mouse} className="cursor-pointer relative">
      <p
        onClick={() => {
          setOpen(!open);
          onclick();
        }}
        className={"w-full py-2 " + classNameBut}
      >
        {text}
      </p>
      {children && (
        <div
          className={`${
            open
              ? `transition-all absolute  max-sm:right-20 max-sm:top-8 border-2 border-Third border-solid !bg-white !text-Third active:text-white rounded-md text-center space-y-2 py-2 px-5  mt-1 z-50 ${className}`
              : "hidden"
          }`}
        >
          {children}
        </div>
      )}
    </div>
  );
}
export default Filter;
