import { useParams } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Add, MainTitle, Table } from "../../components";
function AdsCountries() {
  const { id } = useParams();
  const { content } = useContextTranslate();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/ads/${id}/countries`,
    `admin/ads/${id}/countries`
  );
  const dataAll = data?.data?.data?.map((e) => {
    return {
      id: e?.id,
      name_ar: e.name?.ar,
      name_en: e.name?.en,
    };
  });
  return (
    <div>
      <MainTitle title={content.Advertisement + " » " + content.Countries} />
      <Container>
        <Row justify="between">
          <Col lg={10} className="bg-white shadow-sm rounded-2xl p-5 mx-auto">
            <Add
              link={`/advertisement/countries/${id}/add`}
              titles={content.Countries}
              title={content.Country}
            />
            <Table
              thead={[content.Id, content.NameAr, content.NameEn]}
              pageCount={Math.ceil(
                data?.data.data.total / data?.data.data.per_page
              )}
              tData={dataAll}
              tbody={["id", "name_ar", "name_en"]}
              funDelete={deleteItem}
              isLoading={isLoading}
              isAdmin={true}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default AdsCountries;
