import { AiOutlineCloudUpload } from "react-icons/ai";
import { Col, Row } from "../Grid-system";
import { fileUrl } from "../APIs/useMyAPI";
import { useContextTranslate } from "../../Translate/ContextTranslate";
const AddImage = ({
  id,
  title,
  name,
  onChange,
  newImages,
  className,
  setViewImages,
  setFormData,
  setOldImages,
  error,
  images,
  index,
  setIndex,
}) => {
  const { content } = useContextTranslate();
  const limit = images?.length + newImages?.length;
  const slots = [];

  for (let i = 0; i < 4 - limit; i++) {
    slots[i] = i;
  }
  return (
    <div className="py-3">
      <h1 className="mb-2 px-3 font-semibold">{title || content.addImage}</h1>
      <input
        id={id || "image"}
        type="file"
        name={name}
        onChange={onChange}
        className="hidden"
        accept={"image/*"}
        disabled={limit >= 4}
        multiple
      />
      <div
        onClick={() => document.getElementById(id || "image").click()}
        className={`w-full flex justify-between text-[#9CA3AF] rounded-md my-2 py-[0.3rem] border bg-[#fff] border-[#D2D2D2] px-5 cursor-pointer mx-1 ${className}`}>
        <div className="">{title || content.addImage}</div>
        <div>
          <AiOutlineCloudUpload size={30} className="text-[#9CA3AF]" />
        </div>
      </div>
      <Row className="!mt-6">
        <Col
          col={12}
          className="gap-4 mx-auto w-full flex justify-center flex-wrap">
          {limit < 4 &&
            slots.map(() => (
              <Col>
                <div className="w-40 h-40 border-2 border-gray-400 rounded-lg"></div>
              </Col>
            ))}
          {newImages?.length > 0 &&
            newImages.map((e) => (
              <Col key={e}>
                <div className="relative">
                  <span
                    onClick={() => {
                      setViewImages((prev) =>
                        prev.filter((image) => image !== e)
                      );
                    }}
                    className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute">
                    X
                  </span>
                  <img
                    src={e}
                    alt=""
                    className="object-cover rounded-lg w-40 h-40"
                  />
                </div>
              </Col>
            ))}

          {images?.length > 0 &&
            images.map((e) => (
              <Col key={e.id}>
                <div className="relative">
                  <span
                    onClick={() => {
                      setFormData((prev) => ({
                        ...prev,
                        [`trash_images_ids[${index}]`]: e.id,
                      }));
                      setIndex(index + 1);
                      setOldImages((prev) =>
                        prev.filter((image) => image.id !== e.id)
                      );
                    }}
                    className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute">
                    X
                  </span>
                  <img
                    src={fileUrl + e?.image}
                    alt="Old Profile Pic"
                    className="object-cover rounded-lg w-40 h-40"
                  />
                </div>
              </Col>
            ))}
        </Col>
      </Row>
      {error && <div className="text-red-500 font-semibold">{error}</div>}
    </div>
  );
};

export default AddImage;
