import { MdOutlineReplay } from "react-icons/md";
import Popup from "../Popup/Popup";
import { usePOST } from "../../Tools/APIs/useMyAPI";
import Loading from "../../Tools/Loading";
const Replayed = ({ id }) => {
  const { handleChangeInput, handleSubmit, loading } = usePOST({});
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(`admin/reports/${id}/reply`, "");
  };
  return (
    <div>
      <Popup
        id="replayed"
        text="الرد على هذا المستخدم"
        input={true}
        yes="إرسال"
        name="message"
        onChange={handleChangeInput}
        onClick={handleSubmitMain}
      >
        <MdOutlineReplay size={25} className="text-purple-600" />
      </Popup>
      {loading ? <Loading /> : ""}
    </div>
  );
};

export default Replayed;
