import React, { useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";

const Accordion = ({ list, image, name }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  return (
    <div className="">
      <button
        onClick={() => setAccordionOpen(!accordionOpen)}
        className={`flex justify-between w-full py-2 px-2 block rounded-lg text-sm text-black hover:bg-[#FFF1F4] transition-all ${
          accordionOpen ? "bg-[#FFF1F4]  mb-2" : ""
        }`}
      >
        <div className="flex items-center gap-2">
          <img
            src={image}
            alt=""
            className="w-[14px] h-[14px] object-contain"
          />
          <span className={`text-[#6F757E] !text-[12px]`}>{name}</span>
        </div>
        {
          <RiArrowDownSLine
            size={20}
            className={`${
              accordionOpen
                ? "rotate-180 transition-all"
                : "rotate-0 transition-all"
            }`}
          />
        }
      </button>
      <div
        className={`grid overflow-hidden w-[90%] mr-5 transition-all duration-300 ease-in-out text-[#6F757E] text-sm ${
          accordionOpen
            ? "grid-rows-[1fr] opacity-100"
            : "grid-rows-[0fr] opacity-0"
        }`}
      >
        <div className="overflow-hidden px-1 border-r-2 space-y-1">
          {list.map((e, i) => (
            <li className="" key={i}>
              <NavLink
                to={e.link}
                className="flex items-center gap-2 py-2 px-2 block hover:rounded-lg text-sm text-[#6F757E] hover:bg-Main hover:text-white transition-all"
              >
                <img src={e.image} alt={e.name} className="w-[20px] h-[20px]" />
                <p>{e.name}</p>
              </NavLink>
            </li>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
